import styled from '@emotion/styled';

import { ReactComponent as CloseSvg } from 'images/cmdClose16.svg';

export const CloseSvgIcon = styled(CloseSvg)(({ theme }) => ({
    cursor: 'pointer',
    fill: theme.colors.closeButton,
    width: 32,
    height: 32,
    padding: theme.spacing(2),
    marginLeft: 'auto',
    '&:hover': {
        backgroundColor: theme.colors.closeButtonBackground,
    },
}));
