import { CSSObject } from '@emotion/styled';
import * as RadixDialog from '@radix-ui/react-dialog';
import { FocusOutsideEvent, PointerDownOutsideEvent } from '@radix-ui/react-dismissable-layer';
import React from 'react';

import useXccGuidanceMessagesFix from 'components/templates/MainLayout/hooks/useXccGuidanceMessagesFix';

import { SideNavChildren, SideNavContent, SideNavOverlay } from './SideNav.styles';
import SideNavHeader from './components/SideNavHeader/SideNavHeader';

export interface Props extends React.PropsWithChildren {
    open: boolean;
    title: string;
    modal?: boolean;
    overlaycolor?: string;
    setOpen: (open: boolean) => void;
    style?: CSSObject;
    disableClickOut?: (e: PointerDownOutsideEvent | FocusOutsideEvent) => boolean | undefined;
}

function SideNav({
    children,
    open,
    title,
    setOpen,
    modal = false,
    overlaycolor = 'red',
    style,
    disableClickOut,
}: Props) {
    const { calculateHeight, calculateTop } = useXccGuidanceMessagesFix();
    const finalStyle = {
        ...style,
        ...{
            top: `calc(48px + ${calculateTop().top})`,
            height: `calc( ${calculateHeight().height} - 48px )`,
        },
    };
    return (
        <RadixDialog.Root open={open} onOpenChange={setOpen} modal={modal}>
            <RadixDialog.Portal>
                <SideNavOverlay overlaycolor={overlaycolor} />
                <SideNavContent
                    css={finalStyle}
                    data-testid={'drawerContent'}
                    onInteractOutside={
                        disableClickOut
                            ? (e) => {
                                  if (disableClickOut(e)) {
                                      e.preventDefault();
                                  }
                              }
                            : undefined
                    }
                >
                    {title ? <SideNavHeader header={title} onClose={() => setOpen(false)} /> : null}
                    {children ? <SideNavChildren>{children}</SideNavChildren> : null}
                </SideNavContent>
            </RadixDialog.Portal>
        </RadixDialog.Root>
    );
}

export default SideNav;
