import styled from '@emotion/styled';
import * as Dialog from '@radix-ui/react-dialog';

interface SideNavOverlayProps {
    overlaycolor: string;
}

export const SideNavOverlay = styled(Dialog.Overlay)((props: SideNavOverlayProps) => ({
    backgroundColor: props.overlaycolor,
    position: 'fixed',
    zIndex: 2000,
    inset: 0,
}));

export const SideNavContent = styled(Dialog.Content)(({ theme }) => ({
    background: theme.colors.background,
    boxShadow: '-8px 8px 16px 0 rgb(0 0 0 / 30%)',
    borderRadius: '8px 0px 0px 0px',
    position: 'fixed',
    top: '48px',
    right: '0px',
    minWidth: '200px',
    width: '500px',
    maxWidth: '41vw',
    height: 'calc( 100% - 48px )',
    lineHeight: 1.5,
    fontSize: 12,
    zIndex: 2000,
    outline: 'none',
}));

export const SideNavTitle = styled(Dialog.Title)(({ theme }) => ({
    margin: 0,
    borderRadius: '8px 8px 0px 0px',
    fontWeight: 700,
    color: theme.colors.text,
    backgroundColor: theme.colors.header,
    fontSize: 17,
    height: 48,

    padding: theme.spacing(3, 4, 3, 4),
}));

export const SideNavChildren = styled('div')(() => ({
    height: 'calc(100% - 48px)',
    width: '100%',
}));

export const SideNavComponents = styled('div')(({ theme }) => ({
    height: '100%',
    overflowY: 'auto',
    padding: theme.spacing(3, 4),
}));
