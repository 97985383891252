import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router';

import fetcher from 'providers/api/fetcher';
import {
    REFRESH_ACCESS_TOKEN_INTERVAL,
    redirectToLogin,
} from 'providers/authorization/AuthProvider';
import { Path } from 'types/paths.enum';

export const useCheckSubscription = () => {
    const navigate = useNavigate();
    return useQuery({
        queryKey: ['checkSubscription'],
        queryFn: async (): Promise<boolean> => {
            const response = await fetcher.get('/frontend/subscription/is-active');
            if (response.status === 200) {
                return true;
            } else {
                return Promise.reject(response);
            }
        },
        onError: (error: AxiosError) => {
            if (error.response?.status === 401) {
                redirectToLogin();
            }
        },
        retry: (_failureCount, error: AxiosError) => {
            if (error.response?.status === 402) {
                navigate(Path.ERROR_UNSUBSCRIBED_USER);
                return false;
            } else {
                return _failureCount <= 3;
            }
        },
        refetchInterval: REFRESH_ACCESS_TOKEN_INTERVAL,
    });
};
