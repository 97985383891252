import { func, string } from 'prop-types';
import { useRef } from 'react';

import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import { Center } from 'components/atoms/Flex/Flex';
import { ReactComponent as Upload } from 'images/cmdUpload24.svg';

import { CHOOSE_FILE, INPUT_ID } from './ccdTileUploadFile.consts';

/**
 * Callback for uploading selected files
 *
 * @callback onUploadCallback
 * @param {FileList} object with list of selected files
 */

/**
 * @param message {string} message presented for user inside tile
 * @param onUpload {onUploadCallback} callback function for uploading file.
 */
function CcdTileUploadFile({ message, onUpload }) {
    const uploadRef = useRef();

    return (
        <Center
            css={(props) => ({
                height: '198px',
                boxSizing: 'border-box',
                border: `dashed 1px ${props.colors.tile.textLight}`,
                borderRadius: '3px',
                opacity: 1,
                flexDirection: 'column',
            })}
        >
            <Upload css={(props) => ({ height: '50px', fill: props.colors.tile.textLight })} />
            <Box
                css={(props) => ({
                    color: props.colors.tile.textLight,
                    fontSize: '16px',
                    fontStyle: 'italic',
                    textAlign: 'center',
                })}
            >
                {message}
            </Box>
            <Button
                color={'primary'}
                variant={'contained'}
                css={{
                    marginTop: '8px',
                    width: '98px',
                    height: '32px',
                    cursor: 'pointer',
                }}
                onClick={() => {
                    uploadRef.current.click();
                }}
            >
                {CHOOSE_FILE}
            </Button>
            <input
                id={INPUT_ID}
                type='File'
                hidden
                multiple
                ref={uploadRef}
                onChange={(event) => {
                    onUpload(event.target.files);
                    event.target.value = null;
                }}
            />
        </Center>
    );
}

CcdTileUploadFile.propTypes = {
    requiredMessage: string,
    requiredOnUpload: func,
};

export default CcdTileUploadFile;
