import { useEffect, useState } from 'react';

import appConfig from 'constants/appConfig';
import { useUserDataContext } from 'providers/currentUser/UserDataProvider';
import { useCollaborationSpacePermission } from 'providers/permissions';
import { useGetLcc } from 'services/api/lcc.service';
import useFeatureFlagsQuery from 'services/queries/featuresFlags.query';
import { CollaborationSpacePermissions } from 'types/permissions';

const useSubscriptionManager = () => {
    const [smChecking, setSmChecking] = useState(true);
    const [smEnabled, setSmEnabled] = useState(false);
    const { data: lccId, isLoading, isError } = useGetLcc();
    const [smUrl, setSmUrl] = useState('');
    const { data, isLoading: featuresLoading } = useFeatureFlagsQuery();
    const { collaborationSpaceId } = useUserDataContext();

    const { hasCollaborationSpacePermission, permissionsIsLoading } =
        useCollaborationSpacePermission(collaborationSpaceId);

    useEffect(() => {
        if (!featuresLoading && !permissionsIsLoading) {
            if (
                data?.SubscriptionManagerFeature &&
                hasCollaborationSpacePermission(
                    CollaborationSpacePermissions.SubscriptionManagerView
                )
            ) {
                setSmEnabled(true);
            } else {
                setSmEnabled(false);
            }
            setSmChecking(false);
        }
    }, [featuresLoading, permissionsIsLoading]);

    useEffect(() => {
        if (smEnabled) {
            const lccIdLoaded =
                !isLoading && !isError && lccId
                    ? `${appConfig.SUBSCRIPTION_MANAGER}?lccId=${lccId}`
                    : '';

            setSmUrl(lccIdLoaded);
        }
    }, [smEnabled]);

    return {
        smEnabled,
        smUrl,
        smChecking,
    };
};

export default useSubscriptionManager;
