import React from 'react';

import SmallEmptyState from 'components/atoms/SmallEmptyState';
import { ReactComponent as EmptyStateFile } from 'images/NoNotifications.svg';

import useNotification from '../../services/queries/notification.query';

const EmptyState: React.FC = () => {
    const { data } = useNotification();
    if (data && data.length === 0) {
        return (
            <SmallEmptyState
                message='No notifications to show yet'
                subMessage='You are all caught up!'
                icon={EmptyStateFile}
                id='ccd-notification-empty-state'
            />
        );
    }
    return null;
};

export default EmptyState;
