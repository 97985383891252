import SmallEmptyState from 'components/atoms/SmallEmptyState';
import { ReactComponent as ErrorStateIcon } from 'images/ErrorUsers.svg';

const ErrorState = () => {
    return (
        <SmallEmptyState
            message='Error loading user list'
            subMessage='Try again later...'
            icon={ErrorStateIcon}
            id='project-users-error-state'
        />
    );
};

export default ErrorState;
