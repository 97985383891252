import styled from '@emotion/styled';

import Box from 'components/atoms/Box';
import { ReactComponent as CloseIcon } from 'images/cmdClosePanel24.svg';

export const GuidanceMessageBox = styled(Box)({
    overflow: 'hidden',
    zIndex: 1999,
    height: '32px',
    background: '#EDFBF5',
    border: '#84E3B9',
    borderStyle: 'solid',
    borderLeftStyle: 'none',
    borderRightStyle: 'none',
    fontSize: '12px',
    userSelect: 'none',
    verticalAlign: 'center',
});

export const Info = styled('span')(({ theme }) => ({
    display: 'inline-flex',
    height: '32px',
    alignItems: 'center',
    fontWeight: 500,
    color: 'black',
    margin: theme.spacing(0, 1, 0, 1),
}));

export const Message = styled('span')({
    display: 'inline-flex',
    height: '32px',
    alignItems: 'center',
    fontSize: '12px',
});

export const Link = styled('span')(({ theme }) => ({
    marginLeft: theme.spacing(1),
    display: 'inline-flex',
    height: '32px',
    alignItems: 'center',
    textAlign: 'center',
    color: '#0086B3',
    borderStyle: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
}));

export const CloseButton = styled(CloseIcon)(({ theme }) => ({
    float: 'right',
    position: 'relative',
    top: '5px',
    left: '2px',
    width: '12px',
    height: '12px',
    margin: theme.spacing(1, 5, 0, 0),
}));
