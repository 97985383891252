import fetchBuilder from 'fetch-retry';

export function useCcdFetch() {
    async function ccdFetch(resource: string | Request, options?: RequestInit) {
        const retryFetch = await fetchBuilder(fetch);

        return retryFetch(resource, {
            retries: 5,
            retryDelay: function (attempt: number) {
                return Math.random() * (Math.pow(2, attempt) * 1000);
            },
            credentials: 'include',
            ...options,
        });
    }

    return { ccdFetch };
}

export default useCcdFetch;
