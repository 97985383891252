import React, { useMemo } from 'react';

import { Table } from 'components/_legacy/ccd-table/Table.styles';
import { OnTableSort } from 'pages/AllProjects';
import useFeatureFlagsQuery from 'services/queries/featuresFlags.query';
import { Project } from 'types/project';
import { SelectedProjectData } from 'types/selection';

import DateCellRenderer from './renderers/DateCellRenderer';
import DescriptionCellRenderer from './renderers/DescriptionNameRenderer';
import ProjectNameCellRenderer from './renderers/ProjectNameCellRenderer';

export type ColumnName = 'name' | 'creationDate' | 'lastModifiedDate' | 'lastModifiedBy';
export type TableSortDirection = 'ascending' | 'descending' | 'no-sort' | undefined;

export interface SortTable {
    sortColumn?: ColumnName;
    sortOrder?: TableSortDirection;
}

export type SortSettings = {
    sortedColumnName?: ColumnName;
    sortDirection?: TableSortDirection;
};

interface ProjectsTableViewProps {
    data: Project[];
    onSelected?: (record: Array<SelectedProjectData>) => void;
    onOpen: (record: Project) => void;
    pagination?: object;
    onSorted?: OnTableSort;
    currentSelection?: Project;
    initialSortSettings?: SortSettings;
}

export function ProjectsTable({
    data,
    pagination,
    onSelected,
    onOpen,
    onSorted,
    currentSelection,
    initialSortSettings,
}: ProjectsTableViewProps) {
    const { data: featureFlags, isLoading } = useFeatureFlagsQuery();
    if (isLoading) return null;
    const columnsDescription = useMemo(() => {
        const columns = [
            {
                Header: 'Name',
                accessor: 'name',
                width: '320',
                minWidth: '100',
                ccdSorted: onSorted && true,
                Cell: (data: Project) => ProjectNameCellRenderer(onOpen, data),
            },
            {
                Header: 'Description',
                accessor: 'description',
                width: '450',
                minWidth: '100',
                ccdSorted: false,
                Cell: DescriptionCellRenderer,
            },
            {
                Header: 'Status',
                accessor: 'status',
                width: '83',
                minWidth: '80',
                ccdSorted: false,
            },
            {
                Header: 'Version',
                accessor: 'latestRevision',
                width: '90',
                minWidth: '60',
                ccdSorted: false,
            },
            {
                Header: 'Creation Date',
                accessor: 'creationDate',
                width: '135',
                minWidth: '100',
                ccdSorted: onSorted && true,
                Cell: (data: Project) => DateCellRenderer('creationDate', data),
            },
            {
                Header: 'Modification Date',
                accessor: 'lastModifiedDate',
                width: '120',
                minWidth: '190',
                ccdSorted: onSorted && true,
                Cell: (data: Project) => DateCellRenderer('lastModifiedDate', data),
            },
            {
                Header: 'Last Modification By',
                accessor: 'lastModifiedBy',
                width: '160',
                minWidth: '100',
                ccdSorted: onSorted && true,
            },
            {
                Header: 'Owner',
                accessor: 'owner',
                width: '160',
                minWidth: '200',
                ccdSorted: false,
            },
        ];
        if (featureFlags?.XpeditionSupportFlag && data.find((item) => !!item.tool)) {
            columns.splice(3, 0, {
                Header: 'Uploaded From',
                accessor: 'tool',
                width: '150',
                minWidth: '120',
                ccdSorted: false,
            });
        }
        return columns;
    }, [featureFlags]);

    const onOpenHandler = (project: any) => {
        onOpen?.(project.original);
    };

    return (
        <Table
            data={data}
            pagination={pagination}
            columns={columnsDescription}
            onSorted={onSorted}
            settingsMenu={undefined}
            onColumnResize={undefined}
            rowRenderer={undefined}
            onSelected={onSelected}
            onDoubleClick={onOpenHandler}
            tableSettings={{
                suppressOutsideClick: true,
                initialSortSettings,
            }}
            selectedItem={currentSelection}
        />
    );
}
