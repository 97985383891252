import { useQueryClient } from '@tanstack/react-query';
import { isEqual } from 'lodash';
import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';

import Box from 'components/atoms/Box';
import { Center } from 'components/atoms/Flex/Flex';
import { useProgressBar } from 'components/atoms/ProgressBar';
import { ScanErrorTooltipContainer } from 'components/atoms/ScanErrorTooltipContainer/ScanErrorTooltipContainer';
import { ProjectTextBoxWidth } from 'components/atoms/TextArea/TextArea';
import { UnlockSuspiciousVersionDialog } from 'components/organisms/ProjectVersion/UnlockSuspiciousVersionDialog';
import { LoadingRevisions } from 'components/organisms/ProjectVersion/components/Footer/LoadingRevisions';
import QuickComparison from 'components/organisms/ProjectVersion/components/Footer/QuickComparison';
import { RevisionsLoaded } from 'components/organisms/ProjectVersion/components/Footer/RevisionsLoaded';
import { LoadingFinishedEmptyState } from 'components/organisms/ProjectVersion/components/LoadingFinishedEmptyState';
import { LoadingOngoingEmptyState } from 'components/organisms/ProjectVersion/components/LoadingOngoingEmptyState';
import TooltipContent from 'components/organisms/ProjectVersion/components/TooltipContent';
import useDataMapperHook from 'components/organisms/ProjectVersion/hooks/dataMapperHook';
import { useDeleteRevision } from 'components/organisms/ProjectVersion/hooks/useDeleteRevision';
import { useRestoreRevision } from 'components/organisms/ProjectVersion/hooks/useRestoreRevision';
import { triggerAnalyticsClickEvent } from 'services/SiemensAnalyticsService/SiemensAnalyticsModule';
import analyticsConsts from 'services/SiemensAnalyticsService/analyticsConsts';
import useFeatureFlagsQuery from 'services/queries/featuresFlags.query';
import { useGetProjectQuery, useGetProjectRevisions } from 'services/queries/project.query';
import { ProjectRevision } from 'types/project';

import CurrentVersionContext from './CurrentVersionContext';
import CurrentVersion from './components/CurrentVersion/CurrentVersion';
import Searching from './components/Searching';
import VersionItem from './components/VersionItem/VersionItem';
import WaitingState from './components/WaitingState/WaitingState';
import useSearchRevision from './hooks/searchRevisionsHook';
import useTooltipHandler from './hooks/useTooltipHandler';

import './VersionsChooser.scss';

interface Props {
    collaborationSpaceId: string;
    projectId: string;
    revisionId: number;
    permissions: { canRestoreRevision: boolean; canDeleteRevision: boolean };
}

interface ProjectProps extends Props {
    projectName: string;
}

export interface Comparison {
    firstVersion?: ProjectRevision;
    secondVersion?: ProjectRevision;
    projectProps?: ProjectProps;
}

function VersionsChooser({ collaborationSpaceId, projectId, revisionId, permissions }: Props) {
    const [successVisibility, setSuccessVisibility] = useState(true);
    const [current, setCurrent] = useState(revisionId);
    const [isOpen, setIsOpen] = useState(false);
    const [renderTooltip, setRenderTooltip] = useState(false);
    const [isWaitingState, setIsWaitingState] = useState({
        state: false,
        info: '',
    });
    const [triggerRevisionLoading, setTriggerRevisionLoading] = useState(false);
    const [comparison, setComparison] = useState<Comparison>();
    const { canRestoreRevision, canDeleteRevision } = permissions;
    const { descriptionTooltip, buttonTooltip, disableDescriptionTooltip, disableButtonTooltip } =
        useTooltipHandler();
    const { setProgressBar } = useProgressBar();
    const queryClient = useQueryClient();
    const ref = useRef<HTMLDivElement>(null);
    const { mapData } = useDataMapperHook();
    const { data: featureFlags } = useFeatureFlagsQuery();

    const {
        data: dataInfiniteRevisions,
        fetchNextPage: fetchNextRevisions,
        hasNextPage,
        isLoading,
    } = useGetProjectRevisions({
        collaborationSpaceId,
        projectId: projectId!,
    });

    const { data: currentProjectData } = useGetProjectQuery({
        collaborationSpaceId,
        projectId: projectId!,
    });

    const revisions: ProjectRevision[] = useMemo(
        () =>
            mapData(
                dataInfiniteRevisions?.pages
                    .flat()
                    .map((x) => x.data)
                    .flat() ?? []
            ),
        [dataInfiniteRevisions]
    );

    useEffect(() => {
        if (triggerRevisionLoading) {
            fetchNextRevisions();
        }
    }, [dataInfiniteRevisions, triggerRevisionLoading]);

    const clearWaitingStatus = () => {
        setIsWaitingState({
            state: false,
            info: '',
        });
    };

    const afterRestoreProject = () => {
        const event = new CustomEvent('revisionRestored', {
            detail: {
                eventArgs: { current: newestRevision },
            },
        });
        document.dispatchEvent(event);
        clearWaitingStatus();
    };

    const beforeRestoreProject = () => {
        const event = new CustomEvent('restoringRevision', {
            detail: {
                eventArgs: { current: newestRevision },
            },
        });
        document.dispatchEvent(event);
        setIsWaitingState({
            state: true,
            info: 'Working on restoring version...',
        });
    };

    const afterDeleteProject = () => {
        const event = new CustomEvent('revisionDeleted', {
            detail: {
                eventArgs: { current: newestRevision },
            },
        });
        document.dispatchEvent(event);
    };

    const beforeDeleteProject = () => {
        const event = new CustomEvent('deletingRevision', {
            detail: {
                eventArgs: { current: newestRevision },
            },
        });
        document.dispatchEvent(event);
        setIsWaitingState({
            state: true,
            info: 'Working on deleting version...',
        });
    };

    const setNewestRevisionRequired = () => {
        if (deletedRevisionId === current) {
            const event = new CustomEvent('setCurrentVersion', {
                detail: {
                    eventArgs: { current: newestRevision },
                },
            });
            document.dispatchEvent(event);
        }
    };

    const { openRestoreRevisionDialog, restoreRevisionDialog } = useRestoreRevision({
        setCurrent,
        afterAction: afterRestoreProject,
        beforeAction: beforeRestoreProject,
        collaborationSpaceId,
        projectId,
    });

    const {
        openDeleteRevisionDialog,
        deleteRevisionDialog,
        revisionId: deletedRevisionId,
    } = useDeleteRevision({
        afterAction: async () => {
            clearWaitingStatus();
            afterDeleteProject();
            setNewestRevisionRequired();
            await queryClient.invalidateQueries({ queryKey: ['getProjectRevisions'] });
        },
        beforeAction: beforeDeleteProject,
        collaborationSpaceId,
        projectId,
    });

    const onOpenRestoreDialog = (revisionId: string) => {
        setIsOpen(false);
        openRestoreRevisionDialog(revisionId);
    };

    const onOpenDeleteDialog = (revisionId: string) => {
        setIsOpen(false);
        openDeleteRevisionDialog(revisionId);
    };

    const newestRevision = useMemo(() => {
        if (!revisions) {
            return 1;
        }
        return Math.max(...revisions.map((x) => Number.parseInt(x.versionNumber)));
    }, [revisions]);

    const { criteriaPresent, currentRevisions, currentCriteria, onChange, onClear } =
        useSearchRevision(revisions);

    useEffect(() => {
        const pageClickEvent = (e: MouseEvent) => {
            if (ref.current !== null && !ref.current.contains(e.target as Node)) {
                setIsOpen(false);
            }
        };
        window.addEventListener('mousedown', pageClickEvent);
        return () => {
            window.removeEventListener('mousedown', pageClickEvent);
        };
    }, []);

    useEffect(
        () => () => {
            setProgressBar(false);
        },
        []
    );

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [isOpen, criteriaPresent]);

    useEffect(() => {
        if (revisions === undefined && isLoading) {
            setIsWaitingState({
                state: true,
                info: 'Loading...',
            });
        } else {
            setIsWaitingState({
                state: false,
                info: '',
            });
        }
    }, [isLoading]);

    const onCompareSelect = (projectVersion: ProjectRevision) => {
        if (!currentProjectData) {
            return;
        }
        if (
            isEqual(comparison?.firstVersion, projectVersion) ||
            isEqual(comparison?.secondVersion, projectVersion)
        ) {
            removeCompare(projectVersion);
        } else {
            setComparison((prev) => {
                if (!prev || !prev.firstVersion) {
                    return {
                        ...prev,
                        firstVersion: projectVersion,
                        projectProps: {
                            collaborationSpaceId,
                            projectId,
                            revisionId,
                            permissions,
                            projectName: currentProjectData?.name,
                        },
                    };
                } else if (prev) {
                    return { ...prev, secondVersion: projectVersion };
                }
            });
        }
    };

    const closeCompare = () => {
        setComparison(undefined);
    };

    const removeCompare = (projectVersion: ProjectRevision) => {
        if (!comparison) {
            return;
        }
        const _temp = { ...comparison };
        for (const [key, value] of Object.entries(comparison) as [
            'firstVersion' | 'secondVersion',
            ProjectRevision
        ][]) {
            if (value && value.versionUrn === projectVersion.versionUrn) {
                delete _temp[key];
                setComparison(_temp);
            }
        }
    };

    const onChoose = useCallback((revisionId: string, comment: string) => {
        setCurrent(Number.parseInt(revisionId));

        const event = new CustomEvent('setCurrentVersion', {
            detail: {
                eventArgs: { current: revisionId, comment: comment },
            },
        });
        triggerAnalyticsClickEvent(
            analyticsConsts.Actions.selectVersion,
            analyticsConsts.Categories.navigation,
            analyticsConsts.Sources.projectVersionPicker
        );
        document.dispatchEvent(event);
        setIsOpen(false);
    }, []);

    const putFoundVersions = (revisions?: ProjectRevision[]) => {
        return (
            revisions?.map((x) => {
                return (
                    <VersionItem
                        scanInfo={x.scanInfo}
                        versionNumber={x.versionNumber}
                        versionCreator={x.lastModified.by.name}
                        versionDate={x.lastModified.date}
                        versionDescription={x.description}
                        onRestore={() => {
                            onOpenRestoreDialog(x.versionNumber);
                        }}
                        onDelete={() => {
                            onOpenDeleteDialog(x.versionNumber);
                        }}
                        onChoose={onChoose}
                        key={x.versionNumber}
                        criteria={currentCriteria}
                        restoreButtonVisible={canRestoreRevision}
                        deleteButtonVisible={canDeleteRevision}
                        disableDescriptionTooltip={disableDescriptionTooltip}
                        disableButtonTooltip={disableButtonTooltip}
                        compareButtonVisible={!!featureFlags?.Xcc_XccCompareDesignsFeature}
                        onCompare={() => {
                            onCompareSelect(x);
                        }}
                    />
                );
            }) ?? null
        );
    };

    const putVersions = (revisions: ProjectRevision[]) => {
        return revisions.map((x) => {
            return (
                <VersionItem
                    scanInfo={x.scanInfo}
                    versionNumber={x.versionNumber}
                    versionCreator={x.lastModified.by.name}
                    versionDate={x.lastModified.date}
                    versionDescription={x.description}
                    onRestore={() => {
                        onOpenRestoreDialog(x.versionNumber);
                    }}
                    onDelete={() => {
                        onOpenDeleteDialog(x.versionNumber);
                    }}
                    onChoose={onChoose}
                    key={x.versionNumber}
                    restoreButtonVisible={canRestoreRevision}
                    deleteButtonVisible={canDeleteRevision}
                    disableDescriptionTooltip={disableDescriptionTooltip}
                    disableButtonTooltip={disableButtonTooltip}
                    compareButtonVisible={!!featureFlags?.Xcc_XccCompareDesignsFeature}
                    onCompare={() => {
                        onCompareSelect(x);
                    }}
                />
            );
        });
    };

    const getItems = useCallback((): ReactElement[] | null => {
        if (criteriaPresent) {
            return putFoundVersions(currentRevisions);
        } else if (revisions) {
            return putVersions(revisions);
        } else {
            return null;
        }
    }, [criteriaPresent, currentRevisions, revisions, comparison]);

    const onToggle = useCallback(() => {
        setIsOpen((x) => !x);
    }, []);

    useEffect(() => {
        onClear();
        if (isOpen && !triggerRevisionLoading) {
            setTriggerRevisionLoading(true);
        }
        if (!isOpen) {
            ReactTooltip.hide();
            setRenderTooltip(false);
            return;
        }
        setRenderTooltip(true);
    }, [isOpen]);

    return (
        <>
            <CurrentVersionContext.Provider
                value={{
                    currentRevision: current,
                    newestRevision: newestRevision,
                }}
            >
                <Box onMouseLeave={disableDescriptionTooltip} ref={ref}>
                    <Box
                        as={'button'}
                        id='VersionChooserCurrentItem'
                        aria-expanded='false'
                        onClick={onToggle}
                        css={({ colors }) => ({
                            boxSizing: 'border-box',
                            color: colors.text,
                            cursor: 'pointer',
                            width: ProjectTextBoxWidth,
                            minHeight: '32px',
                            background: colors.background,
                            border: `1px solid ${colors.textArea.border}`,
                            borderRadius: '3px',
                            padding: '2px 8px',
                            ':hover': {
                                boxShadow: colors.boxShadow,
                            },
                        })}
                    >
                        <Box>
                            {isWaitingState.state && <WaitingState info={isWaitingState.info} />}
                            {!isWaitingState.state && revisions && (
                                <CurrentVersion revisions={revisions} isOpen={isOpen} />
                            )}
                        </Box>
                    </Box>
                    {!isWaitingState.state && isOpen && revisions && (
                        <Box
                            id='versionsList'
                            className='ccd-project-version-versions-chooser'
                            css={({ colors }) => ({
                                width: ProjectTextBoxWidth,
                                color: colors.text,
                                backgroundColor: colors.background,
                                display: 'table',
                                flexDirection: 'column',
                            })}
                        >
                            <Searching onChangeCallback={onChange} />
                            <Box
                                id='dropdown-menu-items'
                                css={{
                                    width: '100%',
                                    hover: { border: 'solid 1px red' },
                                    flex: '1 1 1px',
                                }}
                            >
                                {getItems()}
                            </Box>
                            {criteriaPresent &&
                                currentRevisions &&
                                currentRevisions.length === 0 && (
                                    <Center
                                        css={{
                                            height: '217px',
                                            flex: '20 1 100px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        {hasNextPage ? (
                                            <LoadingOngoingEmptyState />
                                        ) : (
                                            <LoadingFinishedEmptyState />
                                        )}
                                    </Center>
                                )}
                            {(dataInfiniteRevisions === undefined || hasNextPage) && (
                                <LoadingRevisions />
                            )}
                            {dataInfiniteRevisions && !hasNextPage && successVisibility && (
                                <RevisionsLoaded
                                    onClick={() => {
                                        setSuccessVisibility(false);
                                    }}
                                />
                            )}
                            {featureFlags?.Xcc_XccCompareDesignsFeature &&
                                comparison &&
                                (comparison.firstVersion || comparison.secondVersion) && (
                                    <QuickComparison
                                        comparison={comparison}
                                        onRemove={removeCompare}
                                        onClose={closeCompare}
                                    />
                                )}
                        </Box>
                    )}
                </Box>
                <ScanErrorTooltipContainer place={'right'} />
                {buttonTooltip && (
                    <ReactTooltip
                        id='tooltip'
                        place='top'
                        effect='solid'
                        type='light'
                        border
                        arrowColor='white'
                        borderColor='#A3A39B'
                        className='ccd-project-version-tooltip'
                    />
                )}
                {renderTooltip && descriptionTooltip && (
                    <ReactTooltip
                        id='descriptionTooltip'
                        place='right'
                        effect='solid'
                        type='light'
                        border
                        arrowColor='white'
                        borderColor='#A3A39B'
                        delayShow={200}
                        className='ccd-project-version-description-tooltip'
                        getContent={TooltipContent}
                        offset={{ right: 8 }}
                    />
                )}
                <UnlockSuspiciousVersionDialog />
                {restoreRevisionDialog}
                {deleteRevisionDialog}
            </CurrentVersionContext.Provider>
        </>
    );
}

export default VersionsChooser;
