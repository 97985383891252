import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import ReactTooltip from 'react-tooltip';

import Box from 'components/atoms/Box';
import { Center } from 'components/atoms/Flex/Flex';
import { showSecurityToast } from 'components/molecules/SecurityToast/SecurityToast';
import currentVersionContext from 'components/organisms/ProjectVersion/CurrentVersionContext';
import TooltipContent from 'components/organisms/ProjectVersion/components/TooltipContent';
import { VersionErrorIcon } from 'components/organisms/ProjectVersion/components/VersionErrorIcon';
import { VersionWarningIcon } from 'components/organisms/ProjectVersion/components/VersionWarningIcon';
import designQuery from 'components/templates/Design/api/query/design.query';
import { ReactComponent as DescriptionIcon } from 'images/ShowCommentaryPanel16.svg';
import { ReactComponent as ArrowDownIcon } from 'images/dropdownArrowDown.svg';
import { ReactComponent as ArrowUpIcon } from 'images/dropdownArrowUp.svg';
import { useUserDataContext } from 'providers/currentUser/UserDataProvider';
import { ProjectRevision, ScanStatus } from 'types/project';

import '../VersionItem/VersionItem.scss';

export default function CurrentVersion({
    revisions,
    isOpen,
}: {
    revisions: ProjectRevision[];
    isOpen: boolean;
}) {
    const [currentRevision, setCurrentRevision] = useState<ProjectRevision>();
    const current = useContext(currentVersionContext);
    const { projectId, revisionId } = useParams();

    const { collaborationSpaceId } = useUserDataContext();

    const { data: currentSingleRevision } = designQuery({
        projectInfo: { collaborationSpaceId, projectId, revisionId },
    });

    const Description = ({ description, version }: { description: string; version: string }) => {
        const preparedDescription = JSON.stringify({ version, description });

        return (
            <>
                <ReactTooltip
                    id='currentVersionTooltip'
                    place='right'
                    effect='solid'
                    type='light'
                    border
                    arrowColor='white'
                    borderColor='#A3A39B'
                    getContent={TooltipContent}
                    offset={{ right: 28 }}
                    className='ccd-project-current-description-tooltip'
                />
                <DescriptionIcon
                    css={({ colors }) => ({
                        fill: colors.text,
                        stroke: colors.text,
                    })}
                    data-for={'currentVersionTooltip'}
                    data-tip={preparedDescription}
                />
            </>
        );
    };

    const currentRevisionFromList = useMemo(() => {
        return revisions?.find((x) => x.versionNumber === revisionId);
    }, [revisions, current]);

    useEffect(() => {
        showSecurityToast(currentRevision);
    }, [currentRevision]);

    useEffect(() => {
        const rev = currentRevisionFromList ?? currentSingleRevision;
        if (
            currentRevision &&
            rev &&
            currentRevision.versionNumber === rev.versionNumber &&
            currentRevision.scanInfo &&
            rev.scanInfo &&
            currentRevision.scanInfo.scanStatus === rev.scanInfo.scanStatus
        ) {
            return;
        }
        setCurrentRevision(rev);
    }, [currentRevisionFromList, currentSingleRevision]);

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [isOpen, currentRevision]);

    if (!currentRevision) {
        return null;
    }

    return (
        revisions && (
            <Box id='currentVersion'>
                <Box
                    css={{
                        display: 'inline-flex',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                >
                    <Box css={{ display: 'flex', width: '100%' }}>
                        <Box
                            id='versionNumber'
                            css={{
                                fontSize: '12px',
                                textAlign: 'left',
                                display: 'block',
                                marginRight: '4px',
                            }}
                        >
                            Version {currentRevision.versionNumber}
                        </Box>
                        <Box css={{ borderLeft: '1px solid #aaa' }} />
                        <Box
                            className='ccd-project-version-version-item-text-version-creator'
                            css={{ marginLeft: '4px' }}
                        >
                            {'Created '}
                            {currentRevision.lastModified.date} by{' '}
                            {currentRevision.lastModified.by.name}
                        </Box>
                        {currentRevision.scanInfo && (
                            <>
                                {currentRevision.scanInfo.scanStatus === ScanStatus.INFECTED && (
                                    <Center css={{ margin: '0 5px' }}>
                                        <VersionErrorIcon
                                            versionNumber={currentRevision.versionNumber}
                                        />
                                    </Center>
                                )}
                                {currentRevision.scanInfo.scanStatus ===
                                    ScanStatus.PENDING_SCAN && (
                                    <Center css={{ margin: '0 5px' }}>
                                        <VersionWarningIcon />
                                    </Center>
                                )}
                            </>
                        )}
                    </Box>
                    <Center css={{ marginRight: '12px' }}>
                        {currentRevision.description && !isOpen ? (
                            <>
                                <Description
                                    description={currentRevision.description}
                                    version={currentRevision.versionNumber}
                                />
                            </>
                        ) : null}
                    </Center>
                    <Center css={{ pointerEvents: 'none' }}>
                        {isOpen ? (
                            <ArrowUpIcon css={({ colors }) => ({ fill: colors.text })} />
                        ) : (
                            <ArrowDownIcon css={({ colors }) => ({ fill: colors.text })} />
                        )}
                    </Center>
                </Box>
            </Box>
        )
    );
}
