import styled from '@emotion/styled';
import { Toolbar } from '@mui/material';
import { PropsWithChildren } from 'react';

import { CcdToastContainer } from 'components/_legacy/ccd-toast';
import Box from 'components/atoms/Box';
import ProgressBar from 'components/atoms/ProgressBar';
import XccGuidanceMessage from 'components/organisms/XccGuidanceMessages';
import useXccGuidanceMessagesFix from 'components/templates/MainLayout/hooks/useXccGuidanceMessagesFix';
import useSessionTimeout from 'hooks/useSessionTimeout';
import { ReactComponent as SiemensLogoSvg } from 'images/miscSiemensLogo24.svg';

import { AppBar, Container, Drawer, Root } from './MainLayout.styles';
import MainMenu from './MainMenu';

interface MainLayoutProps extends PropsWithChildren {
    stretchViewer?: boolean;
}

const SiemensLogo = styled(SiemensLogoSvg)({
    fill: 'white',
    width: 100,
});

const Logo = styled('span')(({ theme }) => ({
    fontSize: '22px',
    fontWeight: 700,
    flexGrow: 1,
    color: theme.colors.headerText,
}));

function MainLayout({ children, stretchViewer = false }: MainLayoutProps) {
    const { calculateHeight, calculateTop } = useXccGuidanceMessagesFix();
    const { sessionTimeoutModal } = useSessionTimeout();
    const toasterPosition = `calc( 60px + ${calculateTop().top})`;

    return (
        <>
            <XccGuidanceMessage />
            <Root data-testid='main-layout' css={calculateHeight()}>
                <CcdToastContainer additionalCss={{ top: toasterPosition }} />
                <ProgressBar />
                {sessionTimeoutModal}
                <Drawer
                    variant='permanent'
                    anchor='left'
                    sx={{ zIndex: 1600 }}
                    PaperProps={{
                        sx: calculateHeight(),
                    }}
                >
                    <Toolbar variant='dense' />
                    <MainMenu />
                </Drawer>
                <Box
                    css={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <AppBar data-testid='main-layout-header-app-bar' css={calculateTop()}>
                        <Toolbar variant='dense'>
                            <Logo>Connect</Logo>
                            <SiemensLogo />
                        </Toolbar>
                    </AppBar>
                    <Container
                        css={(theme) => ({
                            ...(stretchViewer && { padding: theme.spacing(0, 0, 6) }),
                        })}
                        data-testid='main-layout-content'
                    >
                        {children}
                    </Container>
                </Box>
            </Root>
        </>
    );
}

export default MainLayout;
