import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router';

import { useToast } from 'components/_legacy/ccd-toast';
import { useProgressBar } from 'components/atoms/ProgressBar';
import {
    addNewProjectBeginEvent,
    addNewProjectFinishEvent,
} from 'components/templates/ProjectEditView/events';
import { triggerAnalyticsClickEvent } from 'services/SiemensAnalyticsService/SiemensAnalyticsModule';
import analyticsConsts from 'services/SiemensAnalyticsService/analyticsConsts';
import {
    PROJECTS_QUERY,
    PROJECT_QUERY,
    RECENT_PROJECTS_QUERY,
} from 'services/queries/project.query';
import { Path } from 'types/paths.enum';

import { EDIT_MODE, NEW_MODE } from '../consts';
import useCreateNewProject from './useCreateNewProject';
import useEditProject from './useEditProject';

export interface submitInterface {
    collaborationSpaceId: string;
    projectId?: string;
}

function useSubmit({ collaborationSpaceId, projectId }: submitInterface) {
    const queryClient = useQueryClient();
    const navigator = useNavigate();

    function showInfoToast(message: string): void {
        showToast({
            type: 'info',
            messages: [message],
        });
    }

    function showErrorToast(message: string): void {
        showToast({
            type: 'error',
            messages: [message],
        });
    }

    const { mutateAsync: mutateEdit } = useEditProject({
        onSuccess: async () => {
            const projectsQuery = queryClient.invalidateQueries({ queryKey: [PROJECTS_QUERY] });
            const projectQuery = queryClient.invalidateQueries({ queryKey: [PROJECT_QUERY] });
            const recentProjectsQuery = queryClient.invalidateQueries({
                queryKey: [RECENT_PROJECTS_QUERY],
            });
            await Promise.all([projectQuery, projectsQuery, recentProjectsQuery]);
            triggerAnalyticsClickEvent(
                analyticsConsts.Actions.editProject,
                analyticsConsts.Categories.projectActions,
                analyticsConsts.Sources.projectList
            );
        },
        onError: (e) => {
            showErrorToast('Cannot update project.');
            console.error(e);
        },
    });

    const { mutateAsync: mutateCreate } = useCreateNewProject({
        onSuccess: async (response) => {
            await queryClient.invalidateQueries({ queryKey: [PROJECTS_QUERY] });
            const event = new CustomEvent(addNewProjectFinishEvent);
            document.dispatchEvent(event);

            triggerAnalyticsClickEvent(
                analyticsConsts.Actions.createProject,
                analyticsConsts.Categories.projectActions,
                analyticsConsts.Sources.projectList
            );
            showInfoToast('Project created successfully.');
            setTimeout(() => {
                navigator(`${Path.PROJECTS}/${response.projectId}`);
            }, 2000);
        },
        onError: (e) => {
            console.error(e);
            showErrorToast('Cannot create project.');
        },
    });
    const { showToast } = useToast();

    const { setProgressBar } = useProgressBar();

    interface ProjectSubmitData {
        projectName: string;
        projectDescription: string;
        thumbnail: any;
    }

    async function createProjectInternal({
        projectName,
        projectDescription,
        thumbnail,
    }: ProjectSubmitData) {
        const bodyData = prepareBodyData({ projectName, projectDescription, thumbnail });

        const event = new CustomEvent(addNewProjectBeginEvent);
        document.dispatchEvent(event);

        await mutateCreate({
            collaborationSpaceId,
            bodyData,
        });
    }

    interface PrepareBodyDataInterface extends ProjectSubmitData {
        removeStrategy?: (data: FormData) => void;
    }

    async function updateProjectInternal({
        projectName,
        projectDescription,
        thumbnail,
    }: ProjectSubmitData) {
        if (!projectId) throw new Error('projectId cannot be undefined');
        const removeStrategy = (data: FormData) => {
            data.append('removeThumbnail', 'true');
        };

        const bodyData = prepareBodyData({
            projectName,
            projectDescription,
            thumbnail,
            removeStrategy,
        });

        await mutateEdit({ collaborationSpaceId, projectId, bodyData });
    }

    function createBlob(dataURL: string) {
        const BASE64_MARKER = ';base64,';
        if (dataURL.indexOf(BASE64_MARKER) === -1) {
            return null;
        }
        const parts = dataURL.split(BASE64_MARKER);
        const contentType = parts[0].split(':')[1];
        const raw = window.atob(parts[1]);
        const rawLength = raw.length;

        const uInt8Array = new Uint8Array(rawLength);

        for (let i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }

        return new Blob([uInt8Array], { type: contentType });
    }

    function prepareBodyData({
        projectName,
        projectDescription,
        thumbnail,
        removeStrategy,
    }: PrepareBodyDataInterface): FormData {
        const bodyData = new FormData();
        bodyData.append('name', projectName);
        bodyData.append('description', projectDescription);
        if (!thumbnail.removed) {
            if (thumbnail.image) {
                const blob = createBlob(thumbnail.image);
                if (blob) {
                    bodyData.append('file', blob);
                }
            }
        } else {
            if (removeStrategy) {
                removeStrategy(bodyData);
            }
        }
        return bodyData;
    }

    interface SubmitInterface extends ProjectSubmitData {
        currentMode: number;
    }

    async function submit({
        projectName,
        projectDescription,
        thumbnail,
        currentMode,
    }: SubmitInterface) {
        projectName = projectName.trim();
        try {
            if (currentMode === NEW_MODE) {
                await createProjectInternal({ projectName, projectDescription, thumbnail });
            } else if (currentMode === EDIT_MODE) {
                setProgressBar(true);
                await updateProjectInternal({ projectName, projectDescription, thumbnail });
                showInfoToast('Project updated successfully.');
            }
        } catch (ex) {
            if (currentMode === NEW_MODE) {
                const event = new CustomEvent(addNewProjectFinishEvent);
                document.dispatchEvent(event);
            }
        } finally {
            if (currentMode === EDIT_MODE) {
                setProgressBar(false);
            }
        }
    }

    return { submit };
}

export default useSubmit;
