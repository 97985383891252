import { TableSortDirection } from 'components/templates/ProjectsTable/ProjectsTable';
import fetcher from 'providers/api/fetcher';
import { User } from 'providers/currentUser/UserDataProvider';
import {
    AddProjectToRecentRequest,
    AddProjectToRecentResponse,
    GetProjectRevisionRequest,
    GetProjectRevisionsRequest,
    GetProjectsResponse,
    GetRecentProjectsResponse,
    OwnerShip,
    Pagination,
    Project,
    ProjectRevision,
    SimpleProject,
} from 'types/project';

import { ScanInfo } from './../../../types/project';

enum SortDirection {
    ascending = '+',
    descending = '-',
}

interface ProjectRequest {
    maxResults?: number;
    pageToken?: string;
    projectName?: string[];
    projectId?: string[];
    owner?: string[];
    tool?: string;
    toolVersion?: string;
    flow?: string[];
    designType?: string[];
    ownership?: OwnerShip;
    sortColumn?: string;
    sortDirection?: TableSortDirection;
    currentUser?: User;
}

interface IUser {
    name: string;
    urn: string;
}

interface IDesignEntries {
    designType: 'SCHEMATIC' | 'LAYOUT';
    relativePath: string;
}

interface ToolMetadata {
    tool: 'PadsProfessional' | 'XpeditionX';
    version: {
        major: number;
        minor: number;
        update: number;
    };
}

export interface IProject {
    projectId: string;
    name: string;
    description: string;
    versionNumber: string | null;
    lock: {
        lockedBy: {
            name: string;
            urn: string;
        };
        location: string;
    };
    toolMetadata: ToolMetadata | null;
    flow: 'DataManagement' | 'RemoteCollaboration';
    owner: IUser;
    created: {
        by: IUser;
        date: string;
    };
    lastModified: {
        by: IUser;
        date: string;
    };
    designMetadata: {
        designEntries: IDesignEntries[];
    };
    scanInfo?: ScanInfo;
    isShared: boolean;
    thumbnailUri: string;
}

export interface IResponse {
    data: IProject[];
    pagination: {
        next: string;
        current: string;
        previous: string | null;
    };
}

const apiV3SyncFrontend = `api/v3/sync/frontend`;
const apiV3SyncProjects = `api/v3/sync/projects`;

const mapTool = (tool: ToolMetadata | null) => {
    if (!tool) {
        return;
    }
    const version =
        tool.version.minor === 0 && tool.version.minor === 0
            ? ''
            : `${tool.version.major}.${tool.version.minor}`;
    switch (tool.tool) {
        case 'PadsProfessional':
            return `PADS Pro ${version}`;
        case 'XpeditionX':
            return `Xpedition ${version}`;
        default: {
            const exhaustiveCheck: never = tool.tool;
            throw new Error(exhaustiveCheck);
        }
    }
};

const getUserId = (urn: string): string => {
    const splitUrn = urn.split('userId/');
    if (!splitUrn[1]) {
        console.error('Missing userId');
        return '';
    }
    return splitUrn[1];
};

const mapV3toV2 = (data: IProject[], currentUser?: User): Project[] =>
    data.map((item) => {
        return {
            projectId: item.projectId.split('/')[1],
            name: item.name,
            owner: item.owner.name,
            ownerUrn: item.owner.urn,
            isShared: item.isShared,
            description: item.description,
            lastModifiedDate: item.lastModified.date,
            lastModifiedBy: item.lastModified.by.name,
            creationDate: item.created.date,
            latestRevision: item.versionNumber ? item.versionNumber : '',
            status: item.lock ? 'LOCKED' : item.versionNumber ? 'UNLOCKED' : 'NOTSET',
            collaborationSpaceId: item.projectId.split('/')[0],
            thumbnailUri: item.thumbnailUri,
            scanInfo: item.scanInfo,
            isSharedWithMe: item.isShared && getUserId(item.owner.urn) !== currentUser?.userId,
            isSharedByMe: item.isShared && getUserId(item.owner.urn) === currentUser?.userId,
            tool: mapTool(item.toolMetadata),
        };
    });

const sort = (sortColumn: string | undefined, sortDirection: TableSortDirection) => {
    if (!sortColumn || !sortDirection || sortDirection === 'no-sort') return undefined;
    return `${SortDirection[sortDirection]}${sortColumn}`;
};

export const getProjects = async ({
    maxResults = 14,
    projectId,
    projectName,
    flow,
    sortColumn,
    sortDirection,
    ownership,
    owner,
    tool,
    toolVersion,
    pageToken,
    designType,
    currentUser,
}: ProjectRequest): Promise<GetProjectsResponse> => {
    const { data }: { data: IResponse } = await fetcher.get(apiV3SyncProjects, {
        params: {
            'max-results': maxResults,
            'project-id': projectId,
            'project-name': projectName,
            flow,
            sort: sort(sortColumn, sortDirection),
            ownership,
            owner,
            tool,
            'tool-version': toolVersion,
            'page-token': pageToken,
            'design-type': designType,
            thumbnails: true,
        },
    });
    return {
        pagination: data.pagination,
        content: mapV3toV2(data.data, currentUser),
    };
};

export const getRecentProjects = async ({
    currentUser,
}: Pick<ProjectRequest, 'currentUser'>): Promise<GetRecentProjectsResponse> => {
    const { data }: { data: IResponse } = await fetcher.get(`${apiV3SyncProjects}/recent`, {
        params: {
            thumbnails: true,
        },
    });
    return mapV3toV2(data.data, currentUser);
};

export const getProject = async ({
    collaborationSpaceId,
    projectId,
}: {
    collaborationSpaceId?: string;
    projectId?: string;
}): Promise<SimpleProject> => {
    const { data }: { data: { data: SimpleProject } } = await fetcher.get(
        `${apiV3SyncFrontend}/${encodeURIComponent(collaborationSpaceId + '/' + projectId)}`,
        {
            params: {
                thumbnails: true,
            },
        }
    );
    return data.data;
};

export const addProjectToRecent = async ({
    collaborationSpaceId,
    projectId,
}: AddProjectToRecentRequest): Promise<AddProjectToRecentResponse> => {
    const { status } = await fetcher.post(
        `${apiV3SyncProjects}/recent/${encodeURIComponent(collaborationSpaceId + '/' + projectId)}`
    );

    return { status };
};

export const getProjectVersion = async ({
    collaborationSpaceId,
    projectId,
    revisionId,
}: GetProjectRevisionRequest): Promise<ProjectRevision> => {
    const { data } = await fetcher.get(
        `${apiV3SyncFrontend}/${encodeURIComponent(
            collaborationSpaceId + '/' + projectId
        )}/version`,
        {
            params: {
                version: revisionId,
            },
        }
    );
    return data;
};

export const getProjectRevisions = async ({
    collaborationSpaceId,
    projectId,
    pageToken,
}: GetProjectRevisionsRequest & { pageToken?: string }): Promise<{
    data: Array<ProjectRevision>;
    pagination: Pagination;
}> => {
    const { data } = await fetcher.get(
        `${apiV3SyncFrontend}/${encodeURIComponent(
            collaborationSpaceId + '/' + projectId
        )}/versions`,
        {
            params: {
                'max-results': 25,
                'page-token': pageToken,
            },
        }
    );
    return data;
};
