import {
    Container,
    ScrollContainer,
    Toolbar,
} from 'FileManagement/components/parts/ImageViewerModal/ImageViewerModal.styles';
import ReactDOM from 'react-dom';

import Box from 'components/atoms/Box';
import Button, { IButton } from 'components/atoms/Button';
import { useFileManagementContext } from 'components/templates/FileManagement/components/FileManagementContext';
import { useCurrentTopMargin } from 'hooks/useCurrentTopMargin';
import { ReactComponent as CloseImg } from 'images/cmdClosePanel24-white.svg';
import { ReactComponent as DownloadImg } from 'images/cmdDownloadFile24-white.svg';
import { ReactComponent as ResetImg } from 'images/cmdResetImage24.svg';
import { ReactComponent as ZoomInImg } from 'images/cmdZoomIn24.svg';
import { ReactComponent as ZoomOutImg } from 'images/cmdZoomOut24.svg';

import { useDownload } from '../../../hooks';
import { ModalBackdrop } from '../../UI';
import useImageViewerModal from './useImageViewerModal';
import useScrollContainer from './useScrollContainer';

import './ImageViewerModal.scss';

const ViewerButton = (props: IButton) => (
    <Button
        {...props}
        variant={'text'}
        color={'gray'}
        css={{
            backgroundColor: 'transparent !important',
            cursor: 'pointer',
            color: 'white !important',
        }}
    />
);

export function ImageViewerModal({ closeModal }: { closeModal: () => void }) {
    const {
        state: { getRoot },
    } = useFileManagementContext();

    const { topMargin } = useCurrentTopMargin();

    const {
        size,
        image,
        isLoaded,
        imageViewerToolbarId,
        imageViewerContainerId,
        onLoad,
        zoomIn,
        zoomOut,
        stopPropagation,
        resetToOriginalSize,
    } = useImageViewerModal(closeModal);

    const { imgRef, scrollContainerRef, onClickWhileDrag } = useScrollContainer();
    const { anchorRef, downloadFile } = useDownload();

    function loadImage() {
        if (image) {
            return (
                <ScrollContainer
                    draggingClassName={'ccd__image-viewer__scroll-container-ondrag'}
                    innerRef={scrollContainerRef}
                >
                    <img
                        src={image}
                        onLoad={onLoad}
                        width={size.width}
                        height={size.height}
                        alt='Image cannot be displayed'
                        onClick={stopPropagation}
                        ref={imgRef}
                        id='image-viewer-image'
                    />
                </ScrollContainer>
            );
        } else {
            return (
                <Box css={{ color: 'white', textAlign: 'center' }} data-testid={'LoadingImage'}>
                    Loading image. Please wait...
                </Box>
            );
        }
    }

    function getButtons() {
        return (
            <Toolbar id={imageViewerToolbarId}>
                {isLoaded && (
                    <span onClick={stopPropagation}>
                        <ViewerButton
                            onClick={zoomIn}
                            id='zoom-in'
                            leftIcon={ZoomInImg}
                            css={{ cursor: 'zoom-in' }}
                        >
                            Zoom in
                        </ViewerButton>
                        <ViewerButton
                            onClick={zoomOut}
                            id='zoom-out'
                            leftIcon={ZoomOutImg}
                            css={{ cursor: 'zoom-out' }}
                        >
                            Zoom out
                        </ViewerButton>
                        <ViewerButton
                            onClick={resetToOriginalSize}
                            id='reset-to-original-size'
                            leftIcon={ResetImg}
                        >
                            Reset to original size
                        </ViewerButton>
                    </span>
                )}
                <span onClick={stopPropagation}>
                    {isLoaded && (
                        <ViewerButton
                            onClick={downloadFile}
                            id='download-file'
                            leftIcon={DownloadImg}
                        >
                            Download
                        </ViewerButton>
                    )}
                    <ViewerButton onClick={closeModal} id='close-image-viewer' leftIcon={CloseImg}>
                        Close
                    </ViewerButton>
                </span>
            </Toolbar>
        );
    }

    const root = getRoot?.();
    const modalContainer = getRoot?.().getElementById('modal-container');

    if (!root || !modalContainer) {
        return null;
    }

    return (
        <>
            <div onClick={closeModal}>
                <ModalBackdrop rootElement={root} />
                {ReactDOM.createPortal(
                    <Container
                        topMargin={topMargin}
                        onClick={onClickWhileDrag}
                        id={imageViewerContainerId}
                    >
                        {getButtons()}
                        {loadImage()}
                        <div>
                            {/* This div only purpose is to justify content evenly */}
                            <a
                                hidden
                                id='downloadFile-input'
                                ref={anchorRef}
                                onClick={stopPropagation}
                                download
                            />
                        </div>
                    </Container>,
                    modalContainer
                )}
            </div>
        </>
    );
}

export default ImageViewerModal;
