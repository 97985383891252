import { useState } from 'react';
import TagManager from 'react-gtm-module';

import { GTM_AUTH, GTM_ID, GTM_PREVIEW, env } from 'constants/appConfig';
import { User } from 'providers/currentUser/UserDataProvider';

export const useGoogleTagManager = () => {
    const [initialized, setInitialized] = useState<boolean>(false);
    const initGtm = (userData: User, isInternal: boolean) => {
        TagManager.initialize({
            gtmId: GTM_ID as string,
            auth: GTM_AUTH,
            preview: GTM_PREVIEW,
            dataLayer: {
                ecid: userData.customerId,
                uid: userData.userId,
                auid: userData.userId,
                tname: userData.tenantName,
                environment: env === 'production' ? 'prod' : env,
                internal: isInternal,
            },
            dataLayerName: 'userData',
        });

        setInitialized(true);
    };

    const addToUserDataLayer = (name: string, value: string | boolean | undefined | null) => {
        if (initialized) {
            TagManager.dataLayer({
                dataLayer: {
                    [name]: value,
                },
                dataLayerName: 'userData',
            });
        }
    };

    return { initGtm, addToUserDataLayer };
};
