import { ReactComponent as CmdEdit16 } from 'images/cmdEdit16.svg';

import { useSideNavVisibility } from '../../../../components/parts/FileForm';
import { RenameFolderForm } from '../../../../components/parts/Rename';
import { useCommandBarVisibilityContext } from '../../../../hooks';

import '../../../../components/FileManagement.scss';

export default function useRenameFolder() {
    const { visibility, closeSideNav, switchSideNavVisibility } = useSideNavVisibility();
    const { isRenameFolderCommandAccessible } = useCommandBarVisibilityContext();

    return {
        renameFolderForm: (
            <RenameFolderForm
                key='rename-folder-form'
                isVisible={visibility}
                onClose={closeSideNav}
            />
        ),
        renameFolder: {
            id: 'rename-folder',
            children: 'Rename',
            onClick: switchSideNavVisibility,
            leftIcon: CmdEdit16,
            variant: 'text',
            color: 'gray',
            visible: isRenameFolderCommandAccessible,
        },
    };
}
