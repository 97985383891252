import { useQueryClient } from '@tanstack/react-query';
import isNil from 'lodash/isNil';
import { useCallback } from 'react';

import { useFileManagementContext } from 'components/templates/FileManagement/components/FileManagementContext';
import { FILES_QUERY } from 'components/templates/FileManagement/components/parts/Table/service/files.query';

import { DATA_LOADING_TIME_DELAY_IN_MILLIS } from '../consts';

export default function useTableRefresh() {
    const {
        state: { dataLoadToggle, collaborationSpaceId, projectId },
        dispatch: dispatchFileManagement,
    } = useFileManagementContext();

    const queryClient = useQueryClient();

    const dispatchDataLoaded = useCallback(
        function dispatchDataLoaded(selectedFiles) {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    if (!isNil(selectedFiles)) {
                        dispatchFileManagement({ selectedFiles });
                    }

                    queryClient.invalidateQueries({
                        queryKey: [FILES_QUERY, collaborationSpaceId, projectId],
                    });

                    resolve();
                }, DATA_LOADING_TIME_DELAY_IN_MILLIS);
            });
        },
        [dataLoadToggle, dispatchFileManagement]
    );

    return { dispatchDataLoaded };
}
