import { useTheme } from '@emotion/react';

import Box from 'components/atoms/Box';
import Tooltip from 'components/atoms/Tooltip';
import { ReactComponent as BreadCrumbArrow } from 'images/breadCrumbArrow.svg';

import CcdPopupMenu from '../ccd-popup-menu/ccdPopupMenu';

import './ccdBreadcrumb.scss';

export default function CcdBreadcrumb({ breadcrumbArray, onClick, popupId }) {
    const ITEMS_VISIBLE_BEFORE_POPUP = 1;
    const ITEMS_VISIBLE_AFTER_POPUP = 2;
    const MAX_ITEMS_WITHOUT_POPUP = 5;
    const TRUNCATE_LENGTH = 40;

    const theme = useTheme();
    function renderTruncate(item) {
        const isNeedToTruncate = item.length >= TRUNCATE_LENGTH;
        const parsedString = isNeedToTruncate ? item.slice(0, TRUNCATE_LENGTH) + '...' : item;
        if (isNeedToTruncate) {
            return (
                <Tooltip title={item}>
                    <span>{parsedString}</span>
                </Tooltip>
            );
        }
        return <span>{item}</span>;
    }
    function renderBreadcrumb() {
        function createPopupMenu(popupItems) {
            const menuItems = popupItems.map((item) => ({
                name: item.name,
                callback: () => onClick(item),
            }));
            return (
                <CcdPopupMenu
                    key='popup'
                    menuItems={menuItems}
                    id={popupId}
                    menuText='...'
                    direction='right'
                />
            );
        }

        switch (true) {
            case breadcrumbArray.length === 0:
                return [];
            case breadcrumbArray.length <= MAX_ITEMS_WITHOUT_POPUP:
                return createBreadcrumbs(breadcrumbArray, {
                    disableClickOnLastItem: true,
                    beforeSeparator: false,
                    afterSeparator: false,
                });
            default:
                const beforePopupItems = breadcrumbArray.slice(0, ITEMS_VISIBLE_BEFORE_POPUP);
                const popupItems = breadcrumbArray.slice(
                    ITEMS_VISIBLE_BEFORE_POPUP,
                    -1 * ITEMS_VISIBLE_AFTER_POPUP
                );
                const afterPopupItems = breadcrumbArray.slice(
                    breadcrumbArray.length - ITEMS_VISIBLE_AFTER_POPUP
                );
                return [
                    createBreadcrumbs(beforePopupItems, {
                        disableClickOnLastItem: false,
                        beforeSeparator: false,
                        afterSeparator: true,
                    }),
                    createPopupMenu(popupItems),
                    createBreadcrumbs(afterPopupItems, {
                        disableClickOnLastItem: true,
                        beforeSeparator: true,
                        afterSeparator: false,
                    }),
                ];
        }
    }

    function createBreadcrumbs(arr, { disableClickOnLastItem, beforeSeparator, afterSeparator }) {
        return arr.map((item, index, array) => {
            let breadcrumbItem;
            if (disableClickOnLastItem && index + 1 === array.length) {
                breadcrumbItem = createNonClickableBreadcrumb(item);
            } else {
                breadcrumbItem = createClickableBreadcrumb(item);
            }
            return (
                <Box key={item.id} as={'span'} css={{ display: 'flex', flexDirection: 'row' }}>
                    {!(beforeSeparator || afterSeparator) && index > 0 && getSeparator()}
                    <span>
                        {beforeSeparator && getSeparator()}
                        {breadcrumbItem} {item.suffix}
                        {afterSeparator && getSeparator()}
                    </span>
                </Box>
            );
        });
    }

    function createNonClickableBreadcrumb(item) {
        return <span>{renderTruncate(item.name)}</span>;
    }

    function createClickableBreadcrumb(item) {
        return (
            <Box
                as={'span'}
                className='ccd-breadcrumb-element'
                css={{
                    color: theme.colors.actionText,
                    '&:hover': {
                        color: theme.colors.actionTextHover,
                    },
                }}
                onClick={() => {
                    onClick(item);
                }}
            >
                {renderTruncate(item.name)}
            </Box>
        );
    }

    function getSeparator() {
        return (
            <Box as={'span'} css={{ paddingLeft: 6, paddingRight: 6 }}>
                <BreadCrumbArrow />
            </Box>
        );
    }

    return (
        <Box
            className='ccd-breadcrumb'
            css={{
                '& .menu-button-text': {
                    color: theme.colors.actionText,
                    '&:hover': {
                        color: theme.colors.actionTextHover,
                    },
                },
            }}
        >
            {renderBreadcrumb()}
        </Box>
    );
}
