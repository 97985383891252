import { useTheme } from '@emotion/react';
import { useContext } from 'react';
import { useParams } from 'react-router';

import Box from 'components/atoms/Box';
import { Center } from 'components/atoms/Flex/Flex';
import { ScanTooltip } from 'components/organisms/ProjectVersion/components/TooltipContent/SecurityThreatTooltipContent';
import convertDate from 'components/templates/ProjectHistory/ProjectHistoryPanel/utils/RevisionDateToString';
import { ProcessedRevision } from 'components/templates/ProjectHistory/ProjectHistoryView/ProjectHistoryView';
import { ReactComponent as ErrorIcon } from 'images/indicatorError16.svg';
import { ReactComponent as WarningIcon } from 'images/indicatorWarning16.svg';
import { useUserDataContext } from 'providers/currentUser/UserDataProvider';
import { useProjectPermission } from 'providers/permissions';
import { ProjectPermissions } from 'types/permissions';
import { ScanStatus } from 'types/project';

import projectInfoContext from '../ProjectInfoContext';

import './ProjectHistoryItemVersionPanel.scss';

export default function ProjectHistoryItemVersionPanel({
    revision,
}: {
    revision: ProcessedRevision;
}) {
    const { collaborationSpaceId } = useUserDataContext();
    const { projectId } = useParams();
    const { hasProjectPermission } = useProjectPermission(collaborationSpaceId, projectId);
    const theme = useTheme();

    const permission = hasProjectPermission(ProjectPermissions.MarkAsClean);
    const projectInfo = useContext(projectInfoContext);
    const currentlyInUse = projectInfo.currentRevision === +revision.versionNumber;

    const convertedTime = Intl.DateTimeFormat('default', {
        hour: 'numeric',
        minute: 'numeric',
    }).format(new Date(revision.lastModified.date));

    const scanTooltip: ScanTooltip = {
        versionNumber: revision.versionNumber,
        projectId: encodeURIComponent(collaborationSpaceId + '/' + projectId),
        hasPermissionToMarkAsSafe: permission,
    };

    return (
        <div className='version'>
            <Box
                className={'project-history__item-version-panel--internal'}
                css={({ colors }) => ({
                    color: colors.historyPanel.text,
                    border: `1px solid ${colors.historyPanel.text}`,
                    ...(currentlyInUse && {
                        border: `1px solid ${colors.historyPanel.current}`,
                        color: colors.historyPanel.current,
                    }),
                })}
            >
                <div className='project-history__item-version-panel--internal-version'>{`Version ${revision.versionNumber}`}</div>
                <div className='project-history__item-version-panel--internal-date'>
                    {convertDate(revision.lastModified.date)}
                </div>
                <Box
                    className={'project-history__item-version-panel--internal-time'}
                    css={({ colors }) => ({
                        color: colors.historyPanel.time,
                        ...(currentlyInUse && {
                            color: colors.historyPanel.current,
                        }),
                    })}
                >
                    {convertedTime}
                </Box>
                {currentlyInUse && (
                    <div className='project-history__item-version-panel-current-revision'>
                        Currently in use
                    </div>
                )}
                {revision.scanInfo?.scanStatus === ScanStatus.INFECTED && (
                    <Center
                        css={{ fontSize: '16px' }}
                        id={'tooltipScanErrorIcon'}
                        data-for='tooltipScanError'
                        data-tip={JSON.stringify(scanTooltip)}
                    >
                        <ErrorIcon />
                        <Box css={{ marginLeft: '6px', color: theme.colors.error.text }}>
                            Threats detected
                        </Box>
                    </Center>
                )}
                {revision.scanInfo?.scanStatus === ScanStatus.PENDING_SCAN && (
                    <Center
                        css={{ fontSize: '16px' }}
                        id={'tooltipScanErrorIcon'}
                        data-for='tooltipScanWarn'
                        data-tip={'Security scanning...'}
                    >
                        <WarningIcon />
                        <Box css={{ marginLeft: '6px', color: '#EB780A' }}>
                            Security scanning...
                        </Box>
                    </Center>
                )}
            </Box>
        </div>
    );
}
