import React, { PropsWithChildren } from 'react';

import Tooltip from 'components/atoms/Tooltip';
import {
    ProjectFooterDetails,
    ProjectFooterIconsArea,
    ProjectFooterUpperArea,
    TileContent,
} from 'components/templates/ProjectTileFooter/ProjectTileFooter.styles';
import { Tile } from 'components/templates/ProjectTiles/ProjectTiles.styles';
import useRelativeTime, { RelativeDate } from 'hooks/useRelativeTime';
import { ReactComponent as InfectedIconSvg } from 'images/indicatorError16.svg';

import { FileName, FileSpan, FooterInfectedArea, InfectedText } from './FileTile.styled';

interface FileTile extends PropsWithChildren {
    action: 'Created' | 'Uploaded';
    name: string;
    creationDate: RelativeDate;
    createdBy: string;
    onOpen: (event: React.UIEvent<HTMLDivElement>) => void;
    openEnable: boolean;
    isInfected: boolean;
}

const DOUBLE_CLICK = 2;

export default function FileTile({
    children,
    name,
    action,
    creationDate,
    createdBy,
    onOpen,
    openEnable,
    isInfected,
}: FileTile) {
    const { relativeTime } = useRelativeTime();

    const doubleClickHandler = (event: React.UIEvent<HTMLDivElement>) => {
        if (event.detail === DOUBLE_CLICK && openEnable) {
            onOpen(event);
        }
    };

    return (
        <Tile onClick={doubleClickHandler}>
            {children}
            <TileContent>
                <ProjectFooterUpperArea>
                    <FileName
                        data-testid={'TileFileName'}
                        onClick={(event) => {
                            if (openEnable) {
                                onOpen(event);
                            }
                        }}
                        isOpenable={openEnable}
                    >
                        <Tooltip title={name} arrow placement='top'>
                            <FileSpan>{name}</FileSpan>
                        </Tooltip>
                        {isInfected ? (
                            <FooterInfectedArea>
                                <InfectedText>
                                    Security scan detected a threat in this file
                                </InfectedText>
                            </FooterInfectedArea>
                        ) : null}
                    </FileName>
                    <ProjectFooterIconsArea>
                        {isInfected ? <InfectedIconSvg height='24px' width='24px' /> : null}
                    </ProjectFooterIconsArea>
                </ProjectFooterUpperArea>
                <ProjectFooterDetails>
                    {action}&nbsp;
                    {relativeTime({ date: creationDate })}&nbsp;
                    <span>| {createdBy}</span>
                </ProjectFooterDetails>
            </TileContent>
        </Tile>
    );
}
