import React, { useCallback, useEffect, useState } from 'react';

import { ccdErrorToast } from 'components/_legacy/ccd-toast';
import useHttp from 'components/_legacy/hooks/useHttp';
import Box from 'components/atoms/Box';
import { useFileManagementContext } from 'components/templates/FileManagement/components/FileManagementContext';
import { useCurrentTopMargin } from 'hooks/useCurrentTopMargin';
import { ReactComponent as CmdShow16 } from 'images/cmdShow16.svg';

import { CONTACT_SUPPORT_MESSAGE } from '../../../../consts';
import { EE, OPEN_PDF_VIEWER } from '../../../../eventEmitter';
import {
    useCommandBarVisibilityContext,
    useGlobalUiBlockersCounterContext,
} from '../../../../hooks';
import { decodeFileId } from '../../../../utils';

export const errorDuringGettingAccId = {
    title: 'Cannot display pdf file',
    messages: [
        'There was a problem opening pdf file.',
        'Please refresh website.',
        CONTACT_SUPPORT_MESSAGE,
    ],
};
export const pdfViewerEvents = {
    close: 'des-collab-pdf-viewer.close',
    dependencyLoadEnd: 'des-collab-pdf-viewer.dependency-load-end',
};

export default function useDisplayPdf() {
    const {
        state: { backendUrl, collaborationSpaceId, projectId, selectedFiles },
    } = useFileManagementContext();
    const { isDisplayPdfCommandAccessible } = useCommandBarVisibilityContext();
    const [accId, setAccId] = useState(undefined);
    const [isViewerVisible, setIsViewerVisible] = useState(false);
    const [isViewerLoading, setIsViewerLoading] = useState(false);

    const { topMargin } = useCurrentTopMargin();

    const { sendRequest } = useHttp();
    const { increaseBlockerCounter, decreaseBlockerCounter } = useGlobalUiBlockersCounterContext();

    const openPdfViewer = useCallback(() => {
        increaseBlockerCounter();
        const url = new URL('/frontend/acc', backendUrl);
        const requestConfig = {
            url: url,
            method: 'GET',
        };
        const onSuccess = (response) => {
            setAccId(response.id);
            setIsViewerVisible(true);
        };
        const onError = () => {
            decreaseBlockerCounter();
            ccdErrorToast(errorDuringGettingAccId);
        };
        sendRequest({ requestConfig, onSuccess, onError });
    }, [backendUrl]);

    const onOpen = () => {
        if (isDisplayPdfCommandAccessible) {
            openPdfViewer();
        }
    };

    useEffect(() => {
        const hideViewer = () => {
            setIsViewerVisible(false);
            setIsViewerLoading(false);
        };
        const startLoader = () => {
            setIsViewerLoading(true);
            decreaseBlockerCounter();
        };
        window.addEventListener(pdfViewerEvents.close, hideViewer);
        window.addEventListener(pdfViewerEvents.dependencyLoadEnd, startLoader);
        EE.addListener(OPEN_PDF_VIEWER, onOpen);
        return () => {
            window.removeEventListener(pdfViewerEvents.close, hideViewer);
            window.removeEventListener(pdfViewerEvents.dependencyLoadEnd, startLoader);
            EE.removeListener(OPEN_PDF_VIEWER, onOpen);
        };
    }, [isDisplayPdfCommandAccessible]);

    const pdfUrn = isDisplayPdfCommandAccessible
        ? decodeFileId(selectedFiles[0].id).Urn
        : undefined;

    return {
        pdfViewer: isViewerVisible && (
            <Box
                key='displayPdfViewer'
                css={{
                    borderRadius: '24px 24px 0 0',
                    visibility: !isViewerLoading ? 'hidden' : 'visible',
                    position: 'fixed',
                    top: `calc(54px + ${topMargin})`,
                    left: 64,
                    right: 8,
                    height: `calc(100% - 53px - ${topMargin})`,
                    zIndex: 11,
                    background: 'white',
                    '@media (max-width: 460px)': {
                        left: 0,
                        right: 0,
                        top: 77,
                        height: 'calc(100% - 77px)',
                    },
                }}
            >
                <des-collab-pdf-viewer
                    acc-session-id={accId}
                    collaborationspace-id={collaborationSpaceId}
                    container-type='project'
                    container-id={projectId}
                    revision-urn={pdfUrn}
                />
            </Box>
        ),
        displayPdf: {
            id: 'displayPdf',
            children: 'Display PDF',
            onClick: openPdfViewer,
            leftIcon: CmdShow16,
            variant: 'text',
            color: 'gray',
            visible: isDisplayPdfCommandAccessible,
        },
    };
}
