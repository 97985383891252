import { ccdErrorToast, ccdWarningToast } from 'components/_legacy/ccd-toast';
import { ProcessedRevision } from 'components/templates/ProjectHistory/ProjectHistoryView/ProjectHistoryView';
import { ProjectRevision, ScanStatus } from 'types/project';

export const showSecurityToast = (revision: ProcessedRevision | ProjectRevision | undefined) => {
    if (!revision || !revision.scanInfo) return;
    if (revision.scanInfo.scanStatus === ScanStatus.PENDING_SCAN) {
        ccdWarningToast({
            title: 'Security Scan',
            messages: [
                'Your project version is being scanned for security threats.',
                'Opening project and other related actions will be suspended until the scan is complete',
            ],
            autoCloseTime: 7000,
        });
    }
    if (revision.scanInfo.scanStatus === ScanStatus.INFECTED) {
        ccdErrorToast({
            title: 'Security Scan',
            messages: [
                'We detected a security threat in this project version',
                'Opening project and other related actions will be suspended until the threat is removed',
            ],
            autoCloseTime: 7000,
        });
    }
};
