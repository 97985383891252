import styled from '@emotion/styled';
import React, { useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import ReactTooltip from 'react-tooltip';

import { CcdTable, CcdTableColumn } from 'components/_legacy/ccd-table';
import Box from 'components/atoms/Box';
import ProgressSpinner from 'components/atoms/ProgressSpiner';
import VerticalMenu, { TButtons } from 'components/molecules/VerticalMenu';
import { EmptyState } from 'components/templates/Design/components/EmptyState';
import { ErrorState } from 'components/templates/Design/components/ErrorState';
import appConfig from 'constants/appConfig';
import { useRevisionsAction } from 'hooks/useRevisionsAction';
import { ReactComponent as DownloadIcon } from 'images/cmdDownload16.svg';
import { useUserDataContext } from 'providers/currentUser/UserDataProvider';
import { XccInfrastructureProviderContext } from 'providers/xccInfrastructure/XccInfrastructureProvider';
import { basename } from 'shared/path';
import { isScanStatusClean } from 'shared/utility';
import { LoadingStateLabel } from 'types/loadingStateLabel';
import { DesignEntry, ProjectRevision, ScanStatus } from 'types/project';

import CellRenderer from './CellRenderer';
import designQuery from './api/query/design.query';

export interface DesignData {
    relatedFiles: Array<string> | null;
    fileName: string;
    fileType: string;
    lastModifiedBy: string;
    lastModifiedDate: Date;
    collaborationSpaceId: string;
    projectId: string;
    revisionUrn: string;
    revisionId: string;
}

export interface IProjectInfo {
    collaborationSpaceId: string;
    projectId?: string;
    revisionId?: string;
}

const Table = styled(CcdTable)({
    flexGrow: 1,
    '& .ccd-table-row-wrap': {
        height: '32px',
    },
});

const splitPath = (item: DesignEntry) => {
    const fileName = basename(item.relativePath) ?? '';
    const fileNameWithoutExtension: string = fileName.split('.')[0];
    return fileNameWithoutExtension;
};

export default function Design() {
    const { projectId, revisionId } = useParams();
    const { collaborationSpaceId } = useUserDataContext();
    const { revisionsActionInProgress } = useRevisionsAction();
    const [sortedColumn, setSortedColumn] = useState<any>({});
    const xccContext = useContext(XccInfrastructureProviderContext);

    const projectInfo: IProjectInfo = {
        collaborationSpaceId,
        projectId,
        revisionId,
    };

    const { data, isError, error, isLoading } = designQuery({ projectInfo });

    const isScanClean: boolean = isScanStatusClean(data?.scanInfo);
    const scanInfoAvailableAndNotClean = data?.scanInfo && !isScanClean;

    const mapToDesignData = (item: ProjectRevision) => {
        return item.designMetadata.designEntries.map((de) => {
            return {
                relatedFiles: item.relatedFiles,
                fileName: splitPath(de),
                fileType: de.designType,
                lastModifiedBy: item.lastModified.by.name,
                lastModifiedDate: item.lastModified.date,
                collaborationSpaceId: projectInfo.collaborationSpaceId,
                projectId: projectInfo.projectId,
                revisionId: item.versionNumber,
                revisionUrn: item.versionUrn,
            };
        });
    };

    const orderFn = useMemo(() => {
        const modifiedData = data ? mapToDesignData(data) : Array<DesignData>();
        if (sortedColumn && Object.keys(sortedColumn).length > 0 && modifiedData?.length) {
            const direction = sortedColumn.direction;
            const sortingColumn = sortedColumn.column;

            const sorted = modifiedData.slice();
            if (direction === 'ascending') {
                sorted.sort((a: any, b: any) => {
                    return a[sortingColumn?.id] <= b[sortingColumn?.id] ? -1 : 1;
                });
            } else if (direction === 'descending') {
                sorted.sort((a: any, b: any) => {
                    return a[sortingColumn?.id] >= b[sortingColumn?.id] ? -1 : 1;
                });
            }
            return sorted;
        } else {
            return modifiedData;
        }
    }, [sortedColumn, data]);

    const sortingHandler = (column: any, direction: any) => {
        if (direction === 'no-sort') {
            setSortedColumn({});
        } else {
            setSortedColumn({
                column: column,
                direction: direction,
            });
        }
    };

    const columnsDescription: Array<CcdTableColumn<DesignData>> = [
        {
            Header: 'Name',
            accessor: 'fileName',
            width: '400',
            minWidth: 100,
            ccdSorted: true,
        },
        {
            Header: 'Open as',
            accessor: 'name',
            width: '110',
            minWidth: 110,
            ccdSorted: false,
            Cell: (cell) => <CellRenderer cell={cell} scanInfo={data?.scanInfo} />,
        },
        {
            Header: 'Type',
            accessor: 'fileType',
            width: '100',
            minWidth: 100,
            ccdSorted: true,
        },
        {
            Header: 'Modification Date',
            accessor: 'lastModifiedDate',
            width: '190',
            minWidth: 130,
            ccdSorted: false,
        },
        {
            Header: 'Last modified by',
            accessor: 'lastModifiedBy',
            width: '190',
            minWidth: 130,
            ccdSorted: false,
        },
    ];

    const downloadProject = () => {
        const url = `${appConfig.baseURL}/frontend/ecad-files/download/${collaborationSpaceId}/${projectId}/${revisionId}?type=Project`;
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    };

    const notCleanMessages = () => {
        if (data?.scanInfo?.scanStatus === ScanStatus.INFECTED) {
            return 'Actions suspended during security threat';
        }
        return 'Actions suspended during security scanning';
    };

    const getTooltipMessage = () => {
        if (scanInfoAvailableAndNotClean) {
            return notCleanMessages();
        }

        return `Download Project`;
    };

    const buttons: TButtons = [
        {
            id: '1',
            color: 'gray',
            children: 'Download Project',
            variant: 'text',
            leftIcon: DownloadIcon,
            onClick: downloadProject,
            disabled: scanInfoAvailableAndNotClean,
            'data-for': 'download-project-tooltip',
            'data-tip': getTooltipMessage(),
        },
    ];

    const showDesign = () => {
        return (
            <Box
                css={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxHeight: '100%',
                }}
            >
                <VerticalMenu buttons={buttons} />
                <Table
                    columns={columnsDescription as any}
                    data={orderFn}
                    hiddenColumns={undefined}
                    pagination={undefined}
                    onHover={undefined}
                    onSorted={sortingHandler}
                    onOrderChange={undefined}
                    onColumnResize={undefined}
                    settingsMenu={undefined}
                    tableSettings={{
                        wrapText: false,
                        supportsDragNDrop: false,
                        suppressOutsideClick: true,
                    }}
                    rowRenderer={undefined}
                />
                <ReactTooltip
                    id='download-project-tooltip'
                    place='top'
                    effect='solid'
                    type='light'
                    border
                    arrowColor='white'
                    borderColor='#A3A39B'
                    delayHide={500}
                    css={{ zIndex: '2000 !important', opacity: '1 !important' }}
                    className='ccd-project-version-tooltip'
                />
            </Box>
        );
    };

    return (
        <>
            {!data && isError && error.response?.status !== 404 && <ErrorState />}
            {!data && isLoading && <ProgressSpinner label={LoadingStateLabel.DESIGN} />}
            {!data && !isLoading && error?.response?.status === 404 && <EmptyState />}
            {data &&
                data.designMetadata.designEntries.length > 0 &&
                !revisionsActionInProgress &&
                showDesign()}
            {xccContext.xccQuestionDialog}
        </>
    );
}
