import fetcher from 'providers/api/fetcher';

export interface FeaturesFlags {
    Frontend_InAppEventNotificationFeature: boolean;
    SubscriptionManagerFeature: boolean;
    Analytics_SiemensAnalyticsPIIGathering: boolean;
    Analytics_SiemensAuroraAnalyticsEnabled: boolean;
    WalkMeIntegration: boolean;
    XpeditionSupportFlag: boolean;
    Frontend_Theme: boolean;
    Ipl: boolean;
    SynchronizationApi_BomFromCce: boolean;
    Xcc_XccCompareDesignsFeature: boolean;
}

export async function getFeatureFlags(): Promise<FeaturesFlags> {
    const { data } = await fetcher.get('frontend/features');
    return data;
}
