import styled from '@emotion/styled';

export const ActionTileHeaderRoot = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
}));

export const ActionTileHeaderThumbnail = styled('div')(({ theme }) => ({
    marginRight: theme.spacing(2),
    float: 'left',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    flexShrink: 0,
    height: '40px',
}));

export const ActionTileHeaderText = styled('div')(() => ({
    fontSize: '14px',
    fontWeight: 700,
    overflow: 'hidden',
    overflowWrap: 'break-word',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
}));
