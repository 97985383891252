import Box from 'components/atoms/Box';
import { Center } from 'components/atoms/Flex/Flex';
import { ReactComponent as WarningIcon } from 'images/indicatorWarningMonohromatic.svg';

export const LoadingOngoingEmptyState = () => {
    return (
        <>
            <Center>
                <WarningIcon />
                <Box as={'span'} css={{ marginLeft: '8px', fontWeight: '600' }}>
                    No results found!
                </Box>
            </Center>
            <Center>Not all results have been loaded yet.</Center>
            <Center>Please wait for all the results...</Center>
        </>
    );
};
