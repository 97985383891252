import Box from 'components/atoms/Box';
import { Center } from 'components/atoms/Flex/Flex';
import { ReactComponent as WarningIcon } from 'images/indicatorWarningMonohromatic.svg';

export const LoadingFinishedEmptyState = () => {
    return (
        <>
            <Center>
                <WarningIcon />
                <Box as={'span'} css={{ marginLeft: '8px', fontWeight: '600' }}>
                    No results found!
                </Box>
            </Center>
            <Center>Check your search phrase for typos and try again...</Center>
        </>
    );
};
