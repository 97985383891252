import { isEqual, uniqWith } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { useToast } from 'components/_legacy/ccd-toast';
import { useProgressBar } from 'components/atoms/ProgressBar';
import XccQuestionDialog from 'components/organisms/XccQuestionDialog';
import { baseURL } from 'constants/appConfig';
import useXccGuidanceMessages from 'hooks/useXccGuidanceMessages';
import { Path } from 'types/paths.enum';

let processingHandler = undefined;

export const inCacheProjectStatus = 'InCache';
export const notInCacheProjectStatus = 'NotInCache';
export const processingProjectStatus = 'Processing';
export const projectNotFoundStatus = 'NotFound';
export const errorStatus = 'Error';
const CACHE_STATUS_PATH = 'frontend/v1/xcc/cache/status';
const CACHE_PATH = 'frontend/v1/xcc/cache';
const CHECK_INTERVAL = 5000;

const useXccService = () => {
    const [messagesNumber, setMessagesNumber] = useState(0);
    const [currentProcessing, setCurrentProcessing] = useState(false);
    const [showQuestionDialog, setShowQuestionDialog] = useState(false);
    const [currentDesignToProcessing, setCurrentDesignToProcessing] = useState({});
    const navigator = useNavigate();
    const [jobsArray, setJobsArray] = useState([]);
    const [finishedJobsArray, setFinishedJobsArray] = useState([]);
    const [newJobsArray, setNewJobsArray] = useState([]);

    const {
        xccGuidanceMessages,
        isProcessedGuidanceMessageVisible,
        isProcessingGuidanceMessageVisible,
        setProcessedGuidance,
        setProcessingGuidance,
    } = useXccGuidanceMessages({
        openAction: showDesignFromGuidanceMessage,
        closeAction: removeNotification,
    });

    const { setProgressBar } = useProgressBar();
    const { showToast } = useToast();
    function getXccAccessKey() {
        return window.localStorage.getItem('xcc-access-key');
    }

    function removeNotification() {
        finishedJobsArray.shift();
        setFinishedJobsArray(finishedJobsArray ? [...finishedJobsArray] : []);
    }

    async function showDesignFromGuidanceMessage() {
        let job = finishedJobsArray.shift();
        setFinishedJobsArray(finishedJobsArray ? [...finishedJobsArray] : []);
        await showDesign(job);
    }

    function checkIfProjectIsRegistered(design) {
        if (jobsArray.find((job) => isEqual(job, design))) {
            return true;
        }

        if (finishedJobsArray.find((job) => isEqual(job, design))) {
            return true;
        }

        return !!newJobsArray.find((job) => isEqual(job, design));
    }

    function isStatusCorrect(status) {
        return (
            status === inCacheProjectStatus ||
            status === processingProjectStatus ||
            status === notInCacheProjectStatus ||
            status === projectNotFoundStatus
        );
    }

    async function getCacheStatus(designToOpen) {
        let status = '';
        try {
            status = await getProjectStatus(designToOpen);
        } catch (e) {
            console.error(e);
            showErrorToast();
        }
        return status;
    }

    async function openDesignInXcc(design) {
        if (checkIfProjectIsRegistered(design)) {
            return;
        }

        let designToOpen = design;
        try {
            setProgressBar(true);
            const status = await getCacheStatus(designToOpen);
            if (status === '') {
                return;
            }

            if (!isStatusCorrect(status.DesignInCacheStatus)) {
                console.error('Unknown state ' + status.DesignInCacheStatus + ' of ' + design);
                return showErrorToast();
            }

            designToOpen.designVersionId = status.DesignVersionId;
            if (status.DesignInCacheStatus === inCacheProjectStatus) {
                return await showDesign(designToOpen);
            }

            if (status.DesignInCacheStatus === notInCacheProjectStatus) {
                await populateProject(designToOpen);
            }
            setupDialog(designToOpen);
        } catch (e) {
            console.error(e);
            showErrorToast();
        } finally {
            setProgressBar(false);
        }
    }

    function getDesignPart(currentDesign) {
        let pathName = '';
        pathName += '/' + currentDesign.collaborationSpaceId;
        pathName += '/' + currentDesign.projectId;
        pathName += '/' + currentDesign.revision;
        const designType = currentDesign.designType === 'schematic' ? '0' : '1';
        pathName += '/' + designType;
        return pathName;
    }

    function isAnyFinishedJob(finishedJobsArray) {
        return finishedJobsArray.length !== 0;
    }

    function numberOfProcessingJobs() {
        return jobsArray.filter(
            (x) => x.status === processingProjectStatus || x.status === notInCacheProjectStatus
        ).length;
    }

    function getFirstFinishedJob(finishedJobsArray) {
        return finishedJobsArray[0];
    }

    useEffect(() => {
        if (isAnyFinishedJob(finishedJobsArray)) {
            setProcessedState(getFirstFinishedJob(finishedJobsArray));
        } else {
            setProcessedGuidance('');
        }
    }, [finishedJobsArray]);

    useEffect(() => {
        const processedJobsNumber = numberOfProcessingJobs() + newJobsArray.length;

        if (processedJobsNumber === 0) {
            setProcessingGuidance('');
        } else if (processedJobsNumber !== messagesNumber) {
            setProcessingState(processedJobsNumber);
        }
        setMessagesNumber(processedJobsNumber);
    }, [jobsArray, newJobsArray]);

    async function populateProject(currentDesign) {
        let pathName = CACHE_PATH;
        pathName += getDesignPart(currentDesign);

        const url = new URL(pathName, baseURL);
        const response = await fetch(url.toString(), {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'X-Xcc-Temp-Data-Header': getXccAccessKey(),
            },
        });
        if (!response.ok) {
            throw response.statusText;
        }
    }

    function processDesignStatus(job, result) {
        if (!result.DesignInCacheStatus) {
            console.error('Missing DesignInCacheStatus');
            job.status = errorStatus;
        }
        switch (result.DesignInCacheStatus) {
            case inCacheProjectStatus:
            case notInCacheProjectStatus:
            case processingProjectStatus: {
                job.status = result.DesignInCacheStatus;
                break;
            }
            default: {
                throw 'Invalid status';
            }
        }
    }

    function setFinishedJobs(finishedJob) {
        if (finishedJob && finishedJob.length > 0) {
            setFinishedJobsArray((array) => {
                const newArray = array.concat(finishedJob);
                return uniqWith(newArray, isEqual);
            });
        }
    }

    function setJobs(currentJobs) {
        setJobsArray(currentJobs);
    }

    function solveJobs(jobsArray) {
        const finished = jobsArray.filter((x) => x.status === inCacheProjectStatus);
        if (finished.length !== 0) {
            setFinishedJobs(finished);
        }

        return jobsArray.filter(
            (x) => x.status !== errorStatus && x.status !== inCacheProjectStatus
        );
    }

    function isAnyJobToProcess(jobsArray) {
        return jobsArray.length !== 0;
    }

    useEffect(() => {
        processingHandler = setInterval(async () => {
            if (currentProcessing) {
                return;
            }
            setCurrentProcessing(true);
            if (newJobsArray.length !== 0) {
                setJobsArray((jobsArray) => [...jobsArray, ...newJobsArray]);
                setNewJobsArray([]);
                setCurrentProcessing(false);
                return;
            }
            for (let job of jobsArray) {
                try {
                    let result = await getProjectStatus(job);
                    processDesignStatus(job, result);
                } catch (error) {
                    job.status = 'error';
                }
            }
            const solvedJobs = solveJobs(jobsArray);

            if (!isAnyJobToProcess(solvedJobs)) {
                window.clearInterval(processingHandler);
                processingHandler = undefined;
            }
            setJobs(solvedJobs);
            setCurrentProcessing(false);
        }, CHECK_INTERVAL);
        return () => {
            if (processingHandler) {
                clearInterval(processingHandler);
            }
        };
    }, [newJobsArray, currentProcessing]);

    async function getProjectStatus(design) {
        let pathName = CACHE_STATUS_PATH;
        pathName += getDesignPart(design);

        const url = new URL(pathName, baseURL);
        const response = await fetch(url.toString(), {
            method: 'GET',
            credentials: 'include',
            headers: {
                'X-Xcc-Temp-Data-Header': getXccAccessKey(),
            },
        });

        if (response.status === 404) {
            return { DesignInCacheStatus: projectNotFoundStatus };
        }
        if (!response.ok) {
            throw { error: 'Cannot get project state' };
        }
        return await response.json();
    }

    function setProcessedState(data) {
        setProcessedGuidance(`Project view for ${data.name} is ready.`);
    }

    function setProcessingState(number) {
        setProcessingGuidance(`Processing ${number} project ${number === 1 ? 'view' : 'views'}`);
    }

    function showErrorToast() {
        showToast({
            type: 'error',
            title: 'Project view error',
            messages: ['Try again later'],
        });
    }

    function setupDialog(design) {
        setCurrentDesignToProcessing(design);
        setShowQuestionDialog(true);
    }

    function getViewToOpen(design) {
        let designType = [];
        if (design.designType === 'schematic') {
            designType.push('Schematic');
        } else {
            designType.push('Layout');
        }
        return designType;
    }

    async function getAnotherDesignPart(design, part) {
        let designToCheck = { ...design };
        designToCheck.designType = part;
        const status = await getCacheStatus(designToCheck);
        if (!isStatusCorrect(status.DesignInCacheStatus)) {
            console.error('Unknown state ' + status.DesignInCacheStatus + ' of ' + design);
            return false;
        }
        return status.DesignInCacheStatus === inCacheProjectStatus;
    }

    async function getDesignType(design) {
        let designType = [];
        if (design.designType === 'schematic') {
            designType.push('Schematic');
            if ((await getAnotherDesignPart(design, 'layout')) === true) {
                designType.push('Layout');
            }
        } else {
            designType.push('Layout');
            if ((await getAnotherDesignPart(design, 'schematic')) === true) {
                designType.push('Schematic');
            }
        }
        return designType;
    }

    const prepareUrl = (showDesignData) => {
        let preparedUrl = baseURL;
        preparedUrl += '/frontend/v1/xcc/viewer?';
        preparedUrl += 'collaborationSpaceId=' + showDesignData.collaborationSpaceId;
        preparedUrl += '&projectId=' + showDesignData.projectId;
        preparedUrl += '&revision=' + showDesignData.revision;
        preparedUrl += '&mode=' + showDesignData.mode;
        showDesignData.designType.split(',').forEach((x) => (preparedUrl += '&design=' + x));
        return preparedUrl;
    };

    async function showDesign(design) {
        const designType = await getDesignType(design);
        const mode = getViewToOpen(design);
        navigator(`${Path.XCC}/${design.projectId}/${design.revision}/${designType}/${mode}`);
    }

    function waitForProcessReadiness(design) {
        design.status = processingProjectStatus;
        setNewJobsArray((x) => [...x, design]);
    }

    const onQuestionDialogCancel = () => {
        setShowQuestionDialog(false);
        setCurrentDesignToProcessing({});
    };

    const onQuestionDialogAction = () => {
        setShowQuestionDialog(false);
        waitForProcessReadiness(currentDesignToProcessing);
        setCurrentDesignToProcessing({});
    };

    async function isProjectInCache(design) {
        const status = await getCacheStatus(design);
        let returnValue = { status: status.DesignInCacheStatus };
        if (status.DesignInCacheStatus === inCacheProjectStatus) {
            const designType = await getDesignType(design);
            returnValue = { ...returnValue, designType: designType };
        }
        return returnValue;
    }

    return {
        processingGuidanceMessageVisible: isProcessingGuidanceMessageVisible,
        processedGuidanceMessageVisible: isProcessedGuidanceMessageVisible,
        openDesignInXcc,
        xccQuestionDialog: (
            <XccQuestionDialog
                onAction={onQuestionDialogAction}
                onCancel={onQuestionDialogCancel}
                changeState={(state) => {
                    setShowQuestionDialog(state);
                }}
                open={showQuestionDialog}
            />
        ),
        prepareUrl,
        xccGuidanceMassages: xccGuidanceMessages,
        isProjectInCache,
    };
};

export default useXccService;
