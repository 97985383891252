import styled from '@emotion/styled';

import Box from 'components/atoms/Box';

interface SpinnerProps {
    label?: string;
    containerVersion?: boolean;
}

const SpinnerContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    justifyItems: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
}));

export const Spinner = styled('div')(({ theme }) => ({
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.colors.progressIndicator.foreground,
    borderRadius: '50%',
    width: `48px`,
    height: `48px`,

    borderLeftColor: theme.colors.progressIndicator.background,
    borderTopColor: theme.colors.progressIndicator.background,
    animation: 'load 1s infinite linear',
    '@keyframes load': {
        '0%': {
            transform: 'rotate(0deg)',
        },
        '100%': {
            transform: 'rotate(360deg)',
        },
    },
}));

const Label = styled('h4')(({ theme }) => ({
    paddingTop: 2,
    color: theme.colors.text,
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 600,
}));
const ProgressSpinner = ({ label, containerVersion = true }: SpinnerProps) => {
    if (containerVersion) {
        return (
            <SpinnerContainer>
                <Spinner />
                {label && <Label>{label}</Label>}
            </SpinnerContainer>
        );
    }
    return (
        <>
            <Spinner />
            {label && <Label>{label}</Label>}
        </>
    );
};

export default ProgressSpinner;
