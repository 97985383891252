export interface GetProjectRequest {
    collaborationSpaceId: string;
    projectId?: string;
}

export interface SimpleProject {
    projectId: string;
    legacyCollaborationSpaceId: string;
    legacyProjectId: string;
    name: string;
    description: string;
    scanInfo?: ScanInfo;
    thumbnailUri: string;
}

export interface Project {
    id?: string;
    collaborationSpaceId: string;
    creationDate: string;
    description: string;
    isShared: boolean;
    isSharedByMe: boolean;
    isSharedWithMe: boolean;
    lastModifiedBy: string;
    lastModifiedDate: string;
    latestRevision: string;
    name: string;
    owner: string;
    ownerUrn: string;
    projectId: string;
    status: 'LOCKED' | 'UNLOCKED' | 'NOTSET';
    scanInfo?: ScanInfo;
    thumbnailUri: string;
    tool?: string;
}

export interface Pagination {
    next: string | null;
    current: string;
    previous: string | null;
}

export interface ScanInfo {
    scanStatus: ScanStatus;
    scanMessage: string | null;
}

export type ProjectRevision = {
    versionUrn: string;
    versionNumber: string;
    description: string;
    lastModified: LastModifiedDetails;
    designMetadata: {
        designEntries: [
            {
                designType: string;
                relativePath: string;
            },
            {
                designType: string;
                relativePath: string;
            }
        ];
    };
    scanInfo?: ScanInfo;
    relatedFiles: string[];
    status: {
        isOk: boolean;
        description: string;
    };
};

export interface GetProjectsRequest {
    collaborationSpaceId: string;
    ownerShipFilter: OwnerShip;
    pageSize: number;
    startFrom: number;
    sortColumn?: string;
}

export interface GetRecentProjectsRequest {
    collaborationSpaceId: string;
    sortColumn?: string;
    sortOrder?: string;
}

export interface CreateProjectRequest {
    collaborationSpaceId: string;
    bodyData: {};
}

export interface UpdateProjectRequest {
    collaborationSpaceId: string;
    projectId: string;
    bodyData: {};
}

export interface LockProjectRequest {
    collaborationSpaceId: string;
    projectId: string;
}

export interface UnLockProjectRequest {
    collaborationSpaceId: string;
    projectId: string;
    force?: boolean;
}

export interface GetProjectRevisionsRequest {
    collaborationSpaceId: string;
    projectId?: string;
}

export interface GetProjectRevisionRequest {
    collaborationSpaceId: string;
    projectId: string;
    revisionId?: string;
}

export interface DeleteRevisionRequest {
    collaborationSpaceId: string;
    projectId: string;
    revisionId: string;
}

export interface RestoreRevisionRequest {
    collaborationSpaceId: string;
    projectId: string;
    revisionId: string;
}

export interface UpdateProjectResponse {
    status: number;
}

export type GetProjectResponse = Project;
export type GetSimpleProjectResponse = SimpleProject;
export type GetProjectsResponse = {
    totalSize?: number;
    content: Array<Project>;
    pagination?: {
        next: string;
        current: string;
        previous: string | null;
    };
};
export type GetRecentProjectsResponse = Array<Project>;

export type DeleteProjectRequest = Pick<Project, 'collaborationSpaceId' | 'projectId'>;

export type DeleteProjectResponse = {
    status: number;
};

export type LockProjectResponse = {
    status: number;
};

export type UnLockProjectResponse = {
    status: number;
};

export type DeleteRevisionResponse = {
    status: number;
};

export type RestoreRevisionResponse = {
    revisionId: string;
    commitDescription: string;
};

export type Revision = {
    availableAttachmentTypes: Array<string>;
    collaborationSpaceId: string;
    creationDate: string;
    description: string;
    designId: string;
    fileName: string;
    fileType: string;
    lastModifiedBy: string;
    lastModifiedDate: string;
    name: string;
    projectId: string;
    revisionId: string;
    revisionUrn: string;
};

export type ProjectRevisionResponse = Array<Revision>;

export enum ScanStatus {
    CLEAN = 'CLEAN',
    INFECTED = 'INFECTED',
    PENDING_SCAN = 'PENDING_SCAN',
}
export type ModifiedUser = {
    name: string;
    urn: string;
};

export type LastModifiedDetails = {
    by: ModifiedUser;
    date: string;
};

export type DesignEntry = {
    designType: string;
    relativePath: string;
};

export interface AddProjectToRecentRequest {
    collaborationSpaceId: string;
    projectId: string;
}

export type AddProjectToRecentResponse = {
    status: number;
};

export type BomRequest = {
    collaborationSpaceId: string;
    projectId: string;
    startFrom: string;
    pageSize: string;
    projectVersion: string;
    sortOrder?: string;
    sortColumn?: string;
    isSettingsReady?: boolean;
    variant?: string;
    mpnConfigured?: boolean;
};

export type BomResponse = {
    totalSize: number;
    content: Array<Object>;
    defaultColumns: { name: string }[];
    columns: string[];
    defaultFindChipsColumn: string;
    defaultMpnColumn?: string;
};

export type BomSortConfiguration = {
    enabled: boolean;
    direction: string;
    columnName: string;
};

export type BomFindChipConfiguration = {
    columnName: string;
    enabled: boolean;
};

export type BomDisplayConfiguration = {
    wrapText?: boolean;
    hiddenColumns?: Array<string>;
    findChip?: BomFindChipConfiguration;
    columnsModified?: boolean;
};

export type BomOderConfiguration = {
    columnOrder: Array<string>;
};
export type BomSettingsRequest = {
    collaborationSpaceId: string;
    projectId: string;
};

export type BomMpnConfiguration = {
    mpnColumnName: string;
};

export type BomSettingsResponse = {
    sortConfiguration: BomSortConfiguration;
    orderConfiguration: BomOderConfiguration;
    displayConfiguration: BomDisplayConfiguration;
    wrapConfiguration?: string;
    columnWidthsConfiguration?: string;
    mpnConfiguration?: BomMpnConfiguration;
};

export interface ProjectRevisionPagination {
    data: Array<ProjectRevision>;
    pagination: Pagination;
}

export interface MpnConfigRequest {
    collaborationSpaceId: string;
    projectId: string;
    mpnColumnName: string;
}

export interface IProjectOpen extends Pick<Project, 'latestRevision' | 'projectId'> {}

export type OwnerShip = 'OwnedByMe' | 'SharedWithMe' | 'SharedByMe' | undefined;
