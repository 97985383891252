import { ReactComponent as CmdAssignProject16 } from 'images/cmdAssignProject16.svg';

import { useCommandBarVisibilityContext } from '../../../../hooks';
import {
    FilesAlreadyExistsModal,
    useFilesAlreadyExistsModal,
    useSideNavVisibility,
} from '../../../parts';
import { MoveFileForm } from '../../../parts/MoveFileForm';

export default function useMoveFile() {
    const { visibility, closeSideNav, switchSideNavVisibility } = useSideNavVisibility();

    const { isMoveFilesCommandAccessible } = useCommandBarVisibilityContext();

    const { isModalVisible, namesConflicts, hideModal, showModal } = useFilesAlreadyExistsModal();

    return {
        moveFileModal: visibility && (
            <MoveFileForm
                key='move-file-form'
                isVisible={visibility}
                onClose={closeSideNav}
                headerTitle='Move file'
                showModal={showModal}
            />
        ),
        moveFileWarning: isModalVisible && (
            <FilesAlreadyExistsModal
                key='move-file-warning'
                filesNames={namesConflicts}
                onClose={hideModal}
            />
        ),
        moveFile: {
            id: 'moveFileButton',
            children: 'Move',
            onClick: switchSideNavVisibility,
            leftIcon: CmdAssignProject16,
            variant: 'text',
            color: 'gray',
            visible: isMoveFilesCommandAccessible,
        },
    };
}
