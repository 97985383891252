import styled from '@emotion/styled';
import React from 'react';

import Tooltip from 'components/atoms/Tooltip';
import { ReactComponent as InfectedIcon } from 'images/indicatorError16.svg';
import { isScanStatusInfected } from 'shared/utility';
import { Project } from 'types/project';

export default function ProjectNameCellRenderer(openProject: (record: Project) => void, data: any) {
    const openProjectInternal = () => {
        openProject(data.cell.row.original);
    };

    const StyledSpan = styled('span')(({ theme }) => ({
        margin: theme.spacing(0, 1, 0, 0),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        float: 'right',
        cursor: 'pointer',
    }));

    const Paragraph = styled('p')(({ theme }) => ({
        margin: theme.spacing(0, 1, 0, 0),
        fontWeight: 600,
        color: theme.colors.error.text,
        fontSize: '10px',
    }));

    return !isScanStatusInfected(data.cell.row.original.scanInfo) ? (
        <a onClick={openProjectInternal}>{data.cell.value}</a>
    ) : (
        <div>
            <a onClick={openProjectInternal}>{data.cell.value}</a>
            <Tooltip
                title={'Security scan detected a threat in this file'}
                arrow
                enterDelay={500}
                placement='top'
            >
                <StyledSpan id='infectedProject'>
                    <Paragraph>Threats detected</Paragraph> <InfectedIcon />
                </StyledSpan>
            </Tooltip>
        </div>
    );
}
