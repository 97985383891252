import { isEmpty } from 'lodash';
import React, { useState } from 'react';

import Box from 'components/atoms/Box';
import { Center } from 'components/atoms/Flex/Flex';
import { ReactComponent as UploadIcon } from 'images/cmdUploadDrag24.svg';
import { ReactComponent as NoAccessUploadIcon } from 'images/cmdUploadNoAccess24.svg';

export function areAllFiles(items) {
    if (isEmpty(items)) {
        return false;
    }
    for (const item of items) {
        if (item.kind !== 'file') {
            return false;
        }
    }
    return true;
}

export default function DragNDropOverlay({ children, onDrop, hasAccess, id }) {
    const [count, setCounter] = useState(0);

    const showDropZone = (event) => {
        event.preventDefault();
        if (!areAllFiles(event.dataTransfer.items)) {
            return;
        }
        setCounter((prev) => prev + 1);
    };

    const hideDropZone = (event) => {
        event.preventDefault();
        if (!areAllFiles(event.dataTransfer.items)) {
            return;
        }
        setCounter((prev) => prev - 1);
    };

    const onDragOver = (event) => {
        event.preventDefault();
    };

    const onDropHandler = (event) => {
        event.preventDefault();
        if (!areAllFiles(event.dataTransfer.items)) {
            return;
        }
        onDrop(event.dataTransfer.items);
        setCounter(0);
    };

    function getDragMessage() {
        return hasAccess
            ? 'Drag & Drop Files Here'
            : "Your Viewer role doesn't allow to upload files";
    }

    return (
        <Box
            css={{ position: 'relative', overflowY: 'auto', height: '100%', width: '100%' }}
            onDragEnter={showDropZone}
            onDragLeave={hideDropZone}
            onDrop={onDropHandler}
            onDragOver={onDragOver}
            id={id}
        >
            <Center
                css={({ colors }) => ({
                    background: colors.combobox.list.background.hover.default,
                    opacity: '0.8',
                    border: `solid 1px ${colors.combobox.border.focus}`,
                    position: 'absolute',
                    boxSizing: 'border-box',
                    width: '100%',
                    height: '100%',
                    zIndex: '10',
                    pointerEvents: 'none',
                    borderRadius: '3px',
                    ...(count || { display: 'none' }),
                    ...(hasAccess || {
                        background: colors.textArea.warning.background,
                        border: `solid ${colors.textArea.warning.border} 1px`,
                    }),
                })}
            >
                <Center
                    css={{
                        svg: { width: '80px', height: '50px' },
                        flexDirection: 'column',
                    }}
                >
                    {hasAccess ? (
                        <UploadIcon
                            css={({ colors }) => ({
                                fill: colors.actionText,
                            })}
                        />
                    ) : (
                        <NoAccessUploadIcon />
                    )}
                    <Box
                        css={({ colors }) => ({
                            color: colors.actionText,
                            fontSize: '17px',
                            fontStyle: 'italic',
                            textAlign: 'center',
                            ...(hasAccess || { color: colors.textArea.warning.border }),
                        })}
                    >
                        {getDragMessage()}
                    </Box>
                </Center>
            </Center>
            <Box
                css={{
                    ...(count && { pointerEvents: 'none' }),
                    height: '100%',
                }}
            >
                {children}
            </Box>
        </Box>
    );
}
