import React, { useCallback, useState } from 'react';

import Box from 'components/atoms/Box';
import { Input } from 'components/atoms/Input/Input';
import { ReactComponent as Close } from 'images/close.svg';
import { ReactComponent as SearchImg } from 'images/search.svg';

import './Searching.scss';

const styles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
};

export default function Searching({
    onChangeCallback,
}: {
    onChangeCallback: (value: string) => void;
}) {
    const [currentText, setCurrentText] = useState('');
    const [active, setActive] = useState(false);

    const onClickHandler = useCallback((event: React.SyntheticEvent) => {
        event.stopPropagation();
        event.preventDefault();
    }, []);

    const onInputChange = useCallback((event: React.FormEvent<HTMLInputElement>) => {
        setCurrentText(event.currentTarget.value);
        onChangeCallback(event.currentTarget.value);
    }, []);

    const onClear = useCallback((event: React.SyntheticEvent) => {
        setCurrentText('');
        onChangeCallback('');
        event.preventDefault();
        event.stopPropagation();
    }, []);

    const onSetActive = useCallback(() => setActive(true), []);

    return (
        <div className='ccd-project-version-search-field'>
            <div className='ccd-project-version-input-with-icon' onBlur={onSetActive}>
                <Box
                    css={({ colors }) => ({
                        border: `1px solid ${colors.textArea.border}`,
                        outline: 'transparent',
                        display: 'flex',
                        height: '32px',
                        ...(active && {
                            border: `1px solid ${colors.textArea.focus.border}`,
                            borderRadius: '2px',
                        }),
                    })}
                >
                    <Input
                        id='projectVersionSearch'
                        className='ccd-project-version-input-field'
                        value={currentText}
                        type='text'
                        placeholder='Search'
                        onInput={onInputChange}
                        onClick={onClickHandler}
                        onFocus={onSetActive}
                        autoComplete='off'
                        css={({ colors }) => ({
                            color: colors.textArea.text,
                            backgroundColor: colors.background,
                        })}
                    />
                    {currentText ? (
                        <Close
                            css={({ colors }) => ({
                                ...styles,
                                cursor: 'pointer',
                                fill: colors.text,
                            })}
                            onClick={onClear}
                            onFocus={onSetActive}
                        />
                    ) : (
                        <SearchImg
                            css={({ colors }) => ({
                                ...styles,
                                fill: colors.text,
                            })}
                        />
                    )}
                </Box>
            </div>
        </div>
    );
}
