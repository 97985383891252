import { useRef } from 'react';

import { useCommandBar } from 'components/templates/FileManagement/components/parts/CommandBar/hooks';
import { ReactComponent as CmdUpload16 } from 'images/cmdUpload16.svg';

import { CcdInputUpload } from '../../FileUpload/DragNDropUpload';

function useSideNavFileUpload() {
    const ref = useRef();
    const { isSideNavFileUploadVisible } = useCommandBar();

    const UploadInput = ({ onUpload }) => {
        const handleSelectFile = (event) => {
            try {
                onUpload(event.target.files);
            } finally {
                event.target.value = '';
            }
        };

        return (
            <input
                key='upload-file-input'
                type='File'
                name='FileUpload'
                hidden
                id='upload-file-input'
                ref={ref}
                onChange={handleSelectFile}
                multiple
            />
        );
    };

    return {
        uploadForm: (
            <CcdInputUpload key='upload-file-form' id='upload-file-form'>
                {(onInputUpload) => <UploadInput onUpload={onInputUpload} />}
            </CcdInputUpload>
        ),
        sideNavFileUpload: {
            id: 'upload-file',
            children: 'Upload file',
            onClick: (e) => ref.current.click(e),
            leftIcon: CmdUpload16,
            variant: 'text',
            color: 'gray',
            visible: isSideNavFileUploadVisible,
        },
    };
}

export default useSideNavFileUpload;
