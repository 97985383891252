import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';

import Button from 'components/atoms/Button';
import SideNav from 'components/molecules/SideNav';
import {
    DisabledUserComboBox,
    IUserItem,
    UserComboBox,
} from 'components/templates/ProjectUsers/components/UserComboBox';
import {
    IShareProject,
    shareProject,
} from 'components/templates/ProjectUsers/services/query/shareProject.query';
import { useUserDataContext } from 'providers/currentUser/UserDataProvider';
import analyticsConsts from 'services/SiemensAnalyticsService/analyticsConsts';

import RoleComboBox, { Role } from './RoleComboBox';

const addNewUserToProjectSuccessMessage: string = 'User added successfully.';
const addNewUserToProjectFailureMessage: string = 'Cannot add user to project.';
const modifyUserRoleInProjectSuccessMessage: string = 'User role modified successfully.';
const modifyUserRoleInProjectFailureMessage: string = 'Cannot modify user role.';

export enum EUserOperation {
    AddUser,
    ModifyUser,
}

export interface AddUser {
    type: EUserOperation.AddUser;
}

export interface ModifyUser {
    type: EUserOperation.ModifyUser;
    user: IUserItem;
}

interface IUserSideNav extends React.PropsWithChildren {
    open: boolean;
    setOpen: (newState: boolean) => void;
    projectId: string;
    userOperationType: AddUser | ModifyUser;
}

interface IUser {
    title: string;
    restMethod: 'POST' | 'PUT';
    button: string;
    messages: { success: string; fail: string };
    analyticsAction: string;
}

const AddUser: IUser = {
    title: 'Add new user to project',
    restMethod: 'POST',
    button: 'Add',
    messages: {
        success: addNewUserToProjectSuccessMessage,
        fail: addNewUserToProjectFailureMessage,
    },
    analyticsAction: analyticsConsts.Actions.shareProject,
};

const ModifyUser: IUser = {
    title: 'Change user role in project',
    restMethod: 'PUT',
    button: 'Done',
    messages: {
        success: modifyUserRoleInProjectSuccessMessage,
        fail: modifyUserRoleInProjectFailureMessage,
    },
    analyticsAction: analyticsConsts.Actions.modifyUserInProject,
};

const UserSideNav = ({ open, setOpen, projectId, userOperationType }: IUserSideNav) => {
    const [role, setRole] = useState<Role>();
    const [user, setUser] = useState<IUserItem>();

    const operation = userOperationType.type === EUserOperation.AddUser ? AddUser : ModifyUser;

    useEffect(() => {
        if (userOperationType.type === EUserOperation.ModifyUser) {
            setUser(userOperationType.user);
        }
    }, [userOperationType]);

    const [isError, setIsError] = useState(false);

    const cleanup = () => {
        setIsError(false);
        setRole(undefined);
        setUser(undefined);
    };

    const { collaborationSpaceId } = useUserDataContext();

    const share: IShareProject = {
        projectId,
        messages: operation.messages,
        analyticsAction: operation.analyticsAction,
    };
    const { mutate } = shareProject(share);

    const SideNavContent = styled('div')(({ theme }) => ({
        padding: theme.spacing(4),
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    }));

    function getUserCombobox() {
        if (userOperationType.type === EUserOperation.AddUser) {
            return (
                <UserComboBox
                    isError={isError}
                    onChange={(value: IUserItem) => setUser(value)}
                    value={user}
                />
            );
        } else {
            return <DisabledUserComboBox value={userOperationType.user} />;
        }
    }

    return (
        <SideNav
            title={operation.title}
            open={open}
            setOpen={(open) => {
                setOpen(open);
                cleanup();
            }}
            style={{ width: '460px' }}
        >
            <SideNavContent>
                <div>
                    {getUserCombobox()}
                    <RoleComboBox
                        isError={isError}
                        onChange={(value: Role) => {
                            setRole(value);
                        }}
                        value={role}
                    />
                </div>
                <Button
                    css={{
                        width: '100%',
                        height: '24px',
                        cursor: 'pointer',
                    }}
                    variant={'contained'}
                    onClick={() => {
                        if (!user || !role) {
                            setIsError(true);
                            return;
                        }
                        setOpen(false);
                        mutate({
                            email: user.email,
                            role: role.value,
                            projectId,
                            collaborationSpaceId,
                            method: operation.restMethod,
                        });
                        cleanup();
                    }}
                    id={'UserDrawer'}
                >
                    {operation.button}
                </Button>
            </SideNavContent>
        </SideNav>
    );
};

export default UserSideNav;
