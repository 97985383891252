import styled from '@emotion/styled';
import { useNavigate } from 'react-router';

import Box, { BoxProps } from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import { customColors } from 'theme';
import { Path } from 'types/paths.enum';

const Wrapper = styled(Box)({
    width: '100%',
    minWidth: 700,
    height: 'calc(100vh - 120px)',
    minHeight: 450,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
});

const FooterContent = styled(Wrapper)(({ theme }) => ({
    height: 100,
    minHeight: 100,
    '& p': {
        color: '#2D373C',
        margin: theme.spacing(0, 0, 4, 0),
    },
    '& a': {
        margin: theme.spacing(2),
        fontWeight: 700,
        color: customColors['pl-black-12'],
        textDecoration: 'none',
    },
}));

const Content = styled(Box)({
    backgroundColor: 'white',
    borderRadius: 12,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: customColors['pl-black-12'],
    fontStyle: 'normal',
    '& h1': {
        fontWeight: 400,
        fontSize: 30,
        lineHeight: '39px',
    },
    '& p': {
        fontWeight: 400,
        fontSize: 20,
        lineHeight: '27px',
    },
    [`${Box}`]: {
        width: '100%',
    },
});

export const ErrorButton = styled(Button)(({ theme }) => ({
    width: 160,
    marginTop: theme.spacing(4),
    padding: theme.spacing(2, 6),
    cursor: 'pointer',
    fontSize: 14,
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.35)',
    marginRight: theme.spacing(4),
    '&:last-of-type': {
        marginRight: theme.spacing(0),
    },
}));

export interface Props extends BoxProps {
    width?: number;
    height?: number;
    errorLabel?: JSX.Element;
    showLogout?: boolean;
    showReturnHome?: boolean;
    showFooter?: boolean;
}

function ErrorPage({
    children,
    errorLabel,
    width,
    height,
    showLogout = false,
    showReturnHome = true,
    showFooter = true,
}: Props) {
    const navigate = useNavigate();
    return (
        <>
            <Wrapper data-testid='error-page-wrapper'>
                <Content css={{ width, height }}>
                    <Box>{errorLabel}</Box>
                    <Box>{children}</Box>
                    <Box>
                        {showReturnHome && (
                            <ErrorButton
                                onClick={() => navigate(Path.RECENT)}
                                variant={showLogout ? 'outlined' : 'contained'}
                            >
                                Return Home
                            </ErrorButton>
                        )}
                        {showLogout && (
                            <ErrorButton onClick={() => navigate(Path.LOGOUT)} variant='contained'>
                                Log out
                            </ErrorButton>
                        )}
                    </Box>
                </Content>
            </Wrapper>
            {showFooter && (
                <FooterContent>
                    <p>© {new Date().getFullYear()} Siemens Digital Industries Software</p>
                    <Box>
                        <a href='https://www.plm.automation.siemens.com/global/en/legal/privacy-policy.html'>
                            Privacy
                        </a>
                        <a href='https://new.siemens.com/global/en/general/terms-of-use.html'>
                            Terms
                        </a>
                        <a href='https://www.plm.automation.siemens.com/global/en/support/'>Help</a>
                    </Box>
                </FooterContent>
            )}
        </>
    );
}

export default ErrorPage;
