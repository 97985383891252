import styled from '@emotion/styled';
import { Box, Chip } from '@mui/material';

import { ReactComponent as InfectedIconSvg } from 'images/indicatorError16.svg';
import DefaultImage from 'images/tile_view_uniform_icon_small.svg';
import DefaultImageDark from 'images/tile_view_uniform_icon_small_dark.svg';
import { customColors } from 'theme';

const thumbnailHeight = 122;

interface DefaultThumbnailProps {
    selected: boolean;
}

export const DefaultThumbnail = styled(Box)((props: DefaultThumbnailProps) => ({
    backgroundImage: props.selected ? `url(${DefaultImageDark})` : `url(${DefaultImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '50%',
    alignSelf: 'center',
    width: '100%',
    height: '100%',
    '&:hover': {
        backgroundImage: `url(${DefaultImageDark})`,
    },
}));

export const InfectedThumbnail = styled(Box)(() => ({
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    display: 'flex',
    width: '100%',
    height: '100%',
}));

export const Tile = styled(Box)({
    boxShadow: 'rgba(0, 0, 0, 0.3) 0 1px 4px',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    ':hover': {
        [`${DefaultThumbnail}`]: {
            backgroundImage: `url(${DefaultImageDark})`,
        },
    },
});

export const Paragraph = styled('p')(({ theme }) => ({
    margin: theme.spacing(0, 1, 0, 0),
    fontWeight: 400,
    color: theme.colors.error.text,
    fontSize: '10px',
}));

export const InfectedIcon = styled(InfectedIconSvg)(({ theme }) => ({
    width: '24px',
    height: '24px',
    marginBottom: theme.spacing(1),
}));

export const ThumbnailWrapper = styled('div')({
    height: thumbnailHeight,
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
    borderRadius: '5px 5px 0 0',
});

export const Thumbnail = styled('img')({
    objectFit: 'cover',
    width: '100%',
    height: '100%',
});

export const ChipContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),

    '> div': { marginBottom: theme.spacing(2), ':last-child': { marginBottom: 0 } },
}));

const ChipWrapper = styled(Chip)(({ theme }) => ({
    minWidth: '64px',
    height: '16px',
    fontSize: '10px',
    letterSpacing: '0.005em',
    '& > .MuiChip-label': {
        padding: theme.spacing(0, 2, 0, 2),
    },
}));

export const VersionChip = styled(ChipWrapper)(({ theme }) => ({
    backgroundColor: theme.colors.chip.version.background,
    borderColor: theme.colors.chip.version.border,
}));

export const ToolVersionChip = styled(ChipWrapper)(({ theme }) => ({
    backgroundColor: theme.colors.header,
    borderColor: theme.colors.chip.toolVersion.text,
    color: theme.colors.chip.toolVersion.text,
}));
