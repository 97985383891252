import styled from '@emotion/styled';

import { ReactComponent as GuideSvg } from 'images/cmdGuide16.svg';
import { ReactComponent as TermsOfUseSvg } from 'images/cmdHomeDocument16.svg';
import { ReactComponent as DataPrivacySvg } from 'images/homeAccessRule64.svg';

export const GuideIcon = styled(GuideSvg)(({ theme }) => ({
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    color: theme.colors.panelButtonText,
}));

export const DataPrivacyIcon = styled(DataPrivacySvg)(({ theme }) => ({
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    color: theme.colors.panelButtonText,
}));

export const TermsOfUseIcon = styled(TermsOfUseSvg)(({ theme }) => ({
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    color: theme.colors.panelButtonText,
}));

export const ButtonArea = styled('div')(({ theme }) => ({
    fontSize: 12,
    width: '100%',
    height: 32,
    display: 'flex',
    color: theme.colors.panelButtonText,

    '&:hover': {
        backgroundColor: theme.colors.drawerBackground,
    },
}));

export const ButtonPanel = styled('div')({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
});

export const Footer = styled('div')({
    marginTop: 15,
    textAlign: 'center',
    width: '100%',
    fontSize: 12,
});

export const FooterElement = styled('div')<{ fontColor: string }>(({ fontColor }) => ({
    color: fontColor,
    marginTop: 3,
}));
