import CcdEmptyState from 'components/_legacy/ccd-empty-state';
import { ReactComponent as EmptyStateImage } from 'images/Empty-State-icon.svg';
import { ReactComponent as ErrorStateImage } from 'images/Error-State-icon.svg';
import { ReactComponent as InfectedIconSvg } from 'images/indicatorError16.svg';

export function showEmptyState() {
    return (
        <CcdEmptyState
            message='There is no Bill of Materials here yet'
            subMessage='Upload a design in PADS Pro first'
            icon={EmptyStateImage}
            id='BomViewEmptyState'
        />
    );
}

export function showErrorState() {
    return (
        <CcdEmptyState
            message='Failed to load Bill of Materials'
            subMessage='Try to refresh the page'
            icon={ErrorStateImage}
        />
    );
}

export function showInfectedState() {
    return (
        <CcdEmptyState
            message='Security scan detected a threat in this project revision'
            subMessage='Make sure you upload a virus free files'
            icon={InfectedIconSvg}
        />
    );
}
