import { array, func, object } from 'prop-types';

import CcdTableDragAndDropHeaderColumn from '../CcdTableDragAndDropHeaderColumn';

const CcdTableDragAndDropHeader = ({
    currentColOrder,
    headerGroups,
    headerProps,
    onSortedMemo,
    addSortingMenu,
    visibleColumns,
    setColumnOrder,
    initialSortSettings,
    onOrderChange,
}) => {
    const onDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const onDrop = (e) => {
        const colOrder = [...currentColOrder];
        const sIndex = parseInt(e.dataTransfer.getData('draggedIndex'));
        const dIndex = parseInt(e.target.dataset.index);

        if (
            typeof sIndex === 'number' &&
            typeof dIndex === 'number' &&
            dIndex < visibleColumns.length - 1
        ) {
            colOrder.splice(sIndex, 1);
            colOrder.splice(dIndex, 0, e.dataTransfer.getData('dragged'));
            setColumnOrder(colOrder);
            onOrderChange(colOrder);
        }
    };

    const onDragStart = (e) => {
        e.stopPropagation();
        e.dataTransfer.setData('dragged', e.target.dataset.columnId);
        e.dataTransfer.setData('draggedIndex', e.target.dataset.index);
    };

    const onDrag = (e) => e.stopPropagation();

    const onDragEnd = (e) => {
        e.stopPropagation();
    };
    const onDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <div className='ccd-table-header-with-settings'>
            {headerGroups.map((headerGroup, headerGroupIndex) => {
                const headerGroupKey = `tr headerGroupId-${headerGroupIndex}`;

                return (
                    <div
                        key={headerGroupKey}
                        id={headerGroupKey}
                        {...headerGroup.getHeaderGroupProps()}
                        className='ccd-table-header-with-settings__tr'
                    >
                        {headerGroup.headers
                            .filter((column) => column.isVisible)
                            .map((column, index) => (
                                <CcdTableDragAndDropHeaderColumn
                                    key={column.id}
                                    column={column}
                                    headerProps={headerProps}
                                    onDragOver={onDragOver}
                                    onDrop={onDrop}
                                    onDragStart={onDragStart}
                                    onDrag={onDrag}
                                    onDragEnd={onDragEnd}
                                    onDragEnter={onDragEnter}
                                    onSortedMemo={onSortedMemo}
                                    addSortingMenu={addSortingMenu}
                                    headerGroup={headerGroup}
                                    index={index}
                                    initialSortDirection={
                                        initialSortSettings?.sortedColumnName &&
                                        column.id === initialSortSettings.sortedColumnName
                                            ? initialSortSettings.sortDirection
                                            : ''
                                    }
                                />
                            ))}
                    </div>
                );
            })}
        </div>
    );
};

export default CcdTableDragAndDropHeader;
CcdTableDragAndDropHeader.propTypes = {
    currentColOrder: array,
    headerGroups: array,
    headerProps: func,
    onSortedMemo: func,
    addSortingMenu: func,
    visibleColumns: array,
    setColumnOrder: func,
    initialSortSettings: object,
    onOrderChange: func,
};
