import styled from '@emotion/styled';

import { ReactComponent as BellIcon } from 'images/bell.svg';

import { INotification } from '../../INotification';
import useNotification from '../../services/queries/notification.query';

const NotificationCounter = styled('div')(() => ({
    position: 'absolute',
    backgroundColor: 'red',
    height: '12px',
    minWidth: '12px',
    maxWidth: '14px',
    borderRadius: '100%',
    right: '20px',
    top: '8px',
    fontSize: '8px',
}));

const NotificationIcon = () => {
    const { data } = useNotification();

    const counter = data?.reduce
        ? data?.reduce(
              (accumulator: number, item: INotification) =>
                  item.IsRead ? accumulator : accumulator + 1,
              0
          )
        : 0;

    return (
        <div data-testid='notification-bell'>
            <BellIcon css={{ fill: 'white' }} />
            {!!counter && (
                <NotificationCounter data-testid='notification-counter'>
                    {counter < 100 ? counter : '99+'}
                </NotificationCounter>
            )}
        </div>
    );
};

export default NotificationIcon;
