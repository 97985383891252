import styled from '@emotion/styled';
import * as CheckboxRadix from '@radix-ui/react-checkbox';
import { PropsWithChildren, useId } from 'react';

import { customColors } from 'theme';

import { ReactComponent as CheckedIcon } from '../../../images/checked.svg';

interface CheckBoxProps extends CheckboxRadix.CheckboxProps, PropsWithChildren {
    id?: string;
}

const StyledLabelWrapper = styled('label')(({ theme }) => ({
    margin: theme.spacing(1, 0, 1, 0),
    padding: theme.spacing(1, 0, 1, 0),
    border: '2px solid transparent',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    '&:hover': {
        border: `2px solid ${customColors['apollo-hover-blue']}`,
    },
}));

const CheckboxRoot = styled(CheckboxRadix.Root)(({ checked, theme }) => ({
    all: 'unset',
    boxSizing: 'border-box',
    marginLeft: 4,
    border: `1px solid ${
        checked ? theme.colors.checkbox.checkedBackground : theme.colors.checkbox.uncheckedBorder
    }`,
    backgroundColor: checked
        ? theme.colors.checkbox.checkedBackground
        : theme.colors.checkbox.uncheckedBackground,
    width: 18,
    height: 18,
    borderRadius: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
        border: `4px solid ${theme.colors.checkbox.checkedBackground}`,
    },
}));

const CheckboxIndicator = styled(CheckboxRadix.Indicator)(({ theme }) => ({
    display: 'flex',
    boxSizing: 'border-box',
    alignItems: 'center',
    justifyContent: 'center',
    width: 16,
    height: 16,
    padding: 2,
    border: `1px solid ${theme.colors.checkbox.checkedBackground}`,
    borderRadius: 2,
    backgroundColor: theme.colors.checkbox.checkedBackground,
}));

export default function Checkbox({ onCheckedChange, checked, id, children }: CheckBoxProps) {
    const autoId = useId();
    return (
        <StyledLabelWrapper htmlFor={id ?? autoId}>
            <CheckboxRoot checked={checked} id={id ?? autoId} onCheckedChange={onCheckedChange}>
                <CheckboxIndicator>{checked && <CheckedIcon />}</CheckboxIndicator>
            </CheckboxRoot>
            {children}
        </StyledLabelWrapper>
    );
}
