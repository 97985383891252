import { useQueryClient } from '@tanstack/react-query';

import { ccdErrorToast } from 'components/_legacy/ccd-toast';

import { INotification } from '../../INotification';
import { LATEST_NOTIFICATION } from './notification.query';

const useOptimisticUpdate = () => {
    const queryClient = useQueryClient();
    const onMutate = async (
        optimisticUpdate: (notifications: INotification[]) => INotification[]
    ) => {
        // Cancel any outgoing refetches
        // (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries({
            queryKey: [LATEST_NOTIFICATION],
        });

        // Snapshot the previous value
        const previousNotifications = queryClient.getQueryData<INotification[]>([
            LATEST_NOTIFICATION,
        ]);

        // Optimistically update to the new value

        queryClient.setQueryData<INotification[]>([LATEST_NOTIFICATION], (old) => {
            if (!old) {
                return old;
            }
            return optimisticUpdate(old);
        });

        // Return a context object with the snapshotted value
        return { previousTodos: previousNotifications };
    };
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    const onError = (_err: any, newTodo: any, context: any) => {
        ccdErrorToast({
            title: 'Unexpected error',
            messages: [`Refresh site and try again`],
        });
        queryClient.setQueryData([LATEST_NOTIFICATION], context!.previousTodos);
    };

    return { onMutate, onError };
};

export default useOptimisticUpdate;
