import { useTheme } from '@emotion/react';
import { Dispatch, ReactNode, SetStateAction, useState } from 'react';

import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import { useLeftDrawer } from 'components/organisms/LeftDrawer';
import { FilterFunction } from 'components/templates/NotificationPanel/components/DrawerContent/DrawerContent';
import SettingsPanel from 'components/templates/SettingsPanel';
import { ReactComponent as Settings } from 'images/settingsIcon16.svg';

import MenuWrapper from './Menu.styles';

interface IMenuButton {
    name: string;
    onClick: () => void;
}

interface drawersList {
    [key: string]: ReactNode;
}

const drawers: drawersList = {
    settings: <SettingsPanel />,
    empty: null,
};

const Menu = ({
    setFilterFn,
}: {
    setFilterFn: Dispatch<SetStateAction<FilterFunction | undefined>>;
}) => {
    const [selection, setSelection] = useState('All');

    const [currentDrawer, setCurrentDrawer] = useState('');
    const { drawer, toggleDrawer } = useLeftDrawer(drawers[currentDrawer]);

    const toggleSettingsDrawer = () => {
        setCurrentDrawer('settings');
        toggleDrawer();
    };

    const buttonDefinition: IMenuButton[] = [
        {
            name: 'All',
            onClick: () => {
                setFilterFn(undefined);
                //setFilterFn(() => (notifications: INotification[]) => /*your filter function*/); //Example use case. I want to keep it because it is more than sure I forget how it should look like
            },
        },
    ];

    const buttons = buttonDefinition.map((button) => (
        <Button
            key={button.name}
            data-testid={`Notification-Menu-${button.name}`}
            variant={selection === button.name ? 'contained' : 'outlined'}
            color={'primary'}
            css={{ height: '26px', borderRadius: '50px', whiteSpace: 'nowrap' }}
            onClick={() => {
                setSelection(button.name);
                button.onClick();
            }}
        >
            {button.name}
        </Button>
    ));

    const theme = useTheme();

    return (
        <>
            <MenuWrapper>
                <Button
                    leftIcon={Settings}
                    variant={'text'}
                    css={{
                        height: '26px',
                        '*': { fill: theme.colors.text },
                    }}
                    onClick={() => {
                        toggleSettingsDrawer();
                    }}
                />
                <Box
                    css={{
                        width: '100%',
                        display: 'flex',
                        overflowX: 'auto',
                    }}
                >
                    {buttons}
                </Box>
            </MenuWrapper>
            {drawer}
        </>
    );
};

export default Menu;
