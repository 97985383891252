import { CcdEmptyState } from 'components/_legacy/ccd-empty-state';
import { ReactComponent as LoadingIcon } from 'images/loading.svg';

export default function ProjectHistoryViewLoadingState() {
    return (
        <CcdEmptyState
            icon={LoadingIcon}
            message='Project history is loading...'
            subMessage='Please give us a moment'
        />
    );
}
