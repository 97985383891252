import styled from '@emotion/styled';
import { Buffer } from 'buffer';
import { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router';

import { CellRendererProps } from 'components/_legacy/ccd-table';
import { useProgressBar } from 'components/atoms/ProgressBar';
import Tooltip from 'components/atoms/Tooltip';
import { ReactComponent as Button2D } from 'images/type2D48.svg';
import { ReactComponent as Button3D } from 'images/type3D48.svg';
import { ReactComponent as SchematicIcon } from 'images/typeTopicSchemaRevsion48.svg';
import { XccInfrastructureProviderContext } from 'providers/xccInfrastructure/XccInfrastructureProvider';
import { triggerAnalyticsClickEvent } from 'services/SiemensAnalyticsService/SiemensAnalyticsModule';
import analyticsConsts from 'services/SiemensAnalyticsService/analyticsConsts';
import { ScanInfo, ScanStatus } from 'types/project';

import { DesignData } from '../Design';

export function CellRenderer({
    cell,
    scanInfo,
}: {
    cell: CellRendererProps<DesignData>;
    scanInfo?: ScanInfo;
}) {
    const xccContext = useContext(XccInfrastructureProviderContext);
    const { setProgressBar } = useProgressBar();
    const navigator = useNavigate();

    const openJTViewer = () => {
        const bufferObj = Buffer.from(cell.row.original.revisionUrn, 'utf8');
        const x = bufferObj.toString('base64');
        navigator(`/acc/${cell.row.original.projectId}/${cell.row.original.revisionId}/${x}`);
    };

    const openXccViewer = async (type: string) => {
        setProgressBar(true);

        const designToOpen = {
            collaborationSpaceId: cell.row.original.collaborationSpaceId,
            projectId: cell.row.original.projectId,
            revision: cell.row.original.revisionId,
            designType: type,
            name: cell.row.original.fileName,
        };
        await xccContext.openDesignInXcc(designToOpen);
        setProgressBar(false);
    };

    const isScanClean = useMemo(
        () => scanInfo?.scanStatus === ScanStatus.CLEAN,
        [scanInfo?.scanStatus]
    );

    const scanInfoAvailableAndNotClean = scanInfo && !isScanClean;
    const scanInfoNotAvailableOrClean = !scanInfo || isScanClean;

    interface StyledButtonInterface {
        onClick: () => void;
        disabled?: boolean;
    }

    const StyledButton = styled('div')(({ disabled }: StyledButtonInterface) => ({
        marginRight: 21,
        width: 24,
        height: 30,
        float: 'right',
        cursor: disabled ? 'not-allowed' : 'pointer',
        opacity: disabled ? '0.5' : '1',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }));

    const notCleanMessages = () => {
        if (scanInfo?.scanStatus === ScanStatus.INFECTED) {
            return 'Actions suspended during security threat';
        }
        return 'Actions suspended during security scanning';
    };

    const getTooltipMessage = (available: boolean, viewerName: string) => {
        if (!available) {
            return `View ${viewerName} unavailable`;
        }

        if (scanInfoAvailableAndNotClean) {
            return notCleanMessages();
        }

        return `View ${viewerName}`;
    };

    if (cell.row.original.fileType === 'SCHEMATIC') {
        const available = cell.row.original.relatedFiles?.indexOf('CceSchematic') !== -1;
        return (
            <Tooltip
                title={getTooltipMessage(available, 'schematic')}
                arrow
                enterDelay={500}
                placement='top'
            >
                <StyledButton
                    disabled={!available || scanInfoAvailableAndNotClean}
                    id='buttonSchematic'
                    onClick={() => {
                        if (available && scanInfoNotAvailableOrClean) {
                            triggerAnalyticsClickEvent(
                                analyticsConsts.Actions.viewSchematic,
                                analyticsConsts.Categories.versionAction,
                                analyticsConsts.Sources.designTable,
                                'XCC Viewer'
                            );
                            openXccViewer('schematic');
                        }
                    }}
                >
                    <SchematicIcon />
                </StyledButton>
            </Tooltip>
        );
    }

    if (cell.row.original.fileType === 'LAYOUT') {
        const available2d = cell.row.original.relatedFiles?.indexOf('CceLayout') !== -1;
        const available3d = cell.row.original.relatedFiles?.indexOf('JtModel') !== -1;
        return (
            <div>
                <Tooltip
                    title={getTooltipMessage(available3d, '3D PCB')}
                    arrow
                    enterDelay={500}
                    placement='top'
                >
                    <StyledButton
                        disabled={!available3d || scanInfoAvailableAndNotClean}
                        id='button3D'
                        onClick={() => {
                            if (available3d && scanInfoNotAvailableOrClean) {
                                triggerAnalyticsClickEvent(
                                    analyticsConsts.Actions.view3D,
                                    analyticsConsts.Categories.versionAction,
                                    analyticsConsts.Sources.designTable,
                                    'JT Viewer'
                                );
                                openJTViewer();
                            }
                        }}
                    >
                        <Button3D />
                    </StyledButton>
                </Tooltip>
                <Tooltip
                    title={getTooltipMessage(available2d, '2D PCB')}
                    arrow
                    enterDelay={500}
                    placement='top'
                >
                    <StyledButton
                        id='button2D'
                        disabled={!available2d || scanInfoAvailableAndNotClean}
                        onClick={() => {
                            if (available2d && scanInfoNotAvailableOrClean) {
                                triggerAnalyticsClickEvent(
                                    analyticsConsts.Actions.view2D,
                                    analyticsConsts.Categories.versionAction,
                                    analyticsConsts.Sources.designTable,
                                    'XCC Viewer'
                                );
                                openXccViewer('layout');
                            }
                        }}
                    >
                        <Button2D />
                    </StyledButton>
                </Tooltip>
            </div>
        );
    }

    return null;
}

export default CellRenderer;
