import { CcdEmptyState } from 'components/_legacy/ccd-empty-state';
import { ReactComponent as EmptyIcon } from 'images/History24.svg';

export default function ProjectHistoryViewEmptyState() {
    return (
        <CcdEmptyState
            message='No project history to show yet'
            subMessage='Please make your first Check-in in your desktop application'
            icon={EmptyIcon}
            id='HistoryViewEmptyState'
        />
    );
}
