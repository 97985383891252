import { ImageViewerModal } from 'FileManagement/components/parts/ImageViewerModal';
import { useEffect, useState } from 'react';

import { ReactComponent as CmdShow16 } from 'images/cmdShow16.svg';

import { IMAGE_VIEWER_BUTTON } from '../../../../consts';
import { EE, OPEN_IMAGE_VIEWER } from '../../../../eventEmitter';
import { useCommandBarVisibilityContext } from '../../../../hooks';

export default function useImageViewer() {
    const { isDisplayImageCommandAccessible } = useCommandBarVisibilityContext();

    const [visibility, setVisibility] = useState<boolean>(false);
    const onOpen = () => {
        if (isDisplayImageCommandAccessible) {
            showImageViewer();
        }
    };
    useEffect(() => {
        EE.addListener(OPEN_IMAGE_VIEWER, onOpen);
        return () => {
            EE.removeListener(OPEN_IMAGE_VIEWER, onOpen);
        };
    }, [isDisplayImageCommandAccessible]);

    function showImageViewer() {
        setVisibility(true);
    }

    const hideImageViewer = () => {
        setVisibility(false);
    };

    return {
        imageViewerModal: visibility && (
            <ImageViewerModal key={IMAGE_VIEWER_BUTTON} closeModal={hideImageViewer} />
        ),
        imageViewer: {
            id: IMAGE_VIEWER_BUTTON,
            children: 'Display Image',
            onClick: showImageViewer,
            leftIcon: CmdShow16,
            variant: 'text',
            color: 'gray',
            visible: isDisplayImageCommandAccessible,
        },
    };
}
