import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';

import { ccdLoadingToast } from 'components/_legacy/ccd-toast';
import {
    ccdLoadingCompletedWithError,
    ccdLoadingCompletedWithSuccess,
} from 'components/_legacy/ccd-toast';
import useHttp from 'components/_legacy/hooks/useHttp';
import ConfirmationModal from 'components/organisms/ProjectVersion/components/ConfirmationModal';
import { useFileManagementContext } from 'components/templates/FileManagement/components/FileManagementContext';
import { FILES_QUERY } from 'components/templates/FileManagement/components/parts/Table/service/files.query';
import { ReactComponent as CmdDelete16 } from 'images/cmdDelete16.svg';

import { EE, FILE_DELETED } from '../../../../eventEmitter';
import {
    useCommandBarVisibilityContext,
    useGlobalUiBlockersCounterContext,
} from '../../../../hooks';
import { isSuccessfulPromiseFilter, isUnsuccessfulPromiseFilter } from '../../../../utils';
import getMessageStrategy from './deleteMessageStrategies';

export default function useDeleteFiles() {
    const { sendRequest } = useHttp();
    const {
        state: { backendUrl, collaborationSpaceId, projectId, selectedFiles },
        dispatch,
    } = useFileManagementContext();
    const { isDeleteFilesCommandAccessible } = useCommandBarVisibilityContext();
    const [isModalVisible, setIsModalVisible] = useState(false);

    const { increaseBlockerCounter, decreaseBlockerCounter } = useGlobalUiBlockersCounterContext();

    const messageStrategy = getMessageStrategy(selectedFiles);

    const modalText = useMemo(() => {
        return messageStrategy.modalMessage(selectedFiles);
    }, [selectedFiles]);

    const queryClient = useQueryClient();

    const deleteFiles = async () => {
        increaseBlockerCounter();
        hideModal();
        const toastId = ccdLoadingToast({
            messages: messageStrategy.loadingMessage(selectedFiles),
        });
        const selectedFolders = selectedFiles.filter((file) => file.isFolder);
        const selectedDocuments = selectedFiles.filter((file) => !file.isFolder);
        const deleteFoldersPromises = deleteElements(selectedFolders, getDeleteFolderUrl);
        const deleteDocumentsPromises = deleteElements(selectedDocuments, getDeleteDocumentUrl);
        await Promise.allSettled(deleteFoldersPromises.concat(deleteDocumentsPromises)).then(
            async (promiseResults) => {
                if (promiseResults.every(isSuccessfulPromiseFilter)) {
                    ccdLoadingCompletedWithSuccess(toastId, {
                        title: 'Delete successful',
                        messages: messageStrategy.successMessage(selectedFiles),
                    });
                    await queryClient.invalidateQueries({
                        queryKey: [FILES_QUERY, collaborationSpaceId, projectId],
                    });
                } else if (promiseResults.every(isUnsuccessfulPromiseFilter)) {
                    ccdLoadingCompletedWithError(toastId, {
                        title: 'Delete failed',
                        messages: messageStrategy.deleteFailedMessage(),
                        autoCloseTime: 10000,
                    });
                } else {
                    const notDeletedFiles = promiseResults
                        .filter(isUnsuccessfulPromiseFilter)
                        .map((rejectedResults) => rejectedResults.reason.file);
                    ccdLoadingCompletedWithError(toastId, {
                        title: 'Delete failed',
                        messages: messageStrategy.deletePartiallyFailedMessage(),
                        autoCloseTime: 10000,
                    });
                    await dispatchDataLoaded(notDeletedFiles);
                }
                promiseResults.filter(isSuccessfulPromiseFilter).map(() => EE.emit(FILE_DELETED));
            }
        );

        decreaseBlockerCounter();
    };

    const showModal = useCallback(() => {
        setIsModalVisible(true);
    }, []);

    const hideModal = useCallback(() => {
        setIsModalVisible(false);
    }, []);

    function deleteElements(elements, urlCreationFunc) {
        if (!elements) {
            return [];
        }
        const promises = [];
        for (const element of elements) {
            const promise = new Promise((resolve, reject) => {
                const requestConfig = {
                    url: urlCreationFunc(element.id),
                    method: 'DELETE',
                };
                const onError = (error) => {
                    error.file = element;
                    reject(error);
                };

                const onSuccess = () => {
                    resolve(element);
                    dispatch({
                        selectedFiles: [],
                    });
                };

                sendRequest({
                    requestConfig,
                    onError,
                    onSuccess,
                });
            });
            promises.push(promise);
        }
        return promises;
    }

    function getDeleteFolderUrl(folderId) {
        return new URL(
            `/frontend/file-management/${collaborationSpaceId}/${projectId}/folder/${folderId}`,
            backendUrl
        );
    }

    function getDeleteDocumentUrl(fileId) {
        return new URL(
            `/frontend/file-management/${collaborationSpaceId}/${projectId}/document/${fileId}`,
            backendUrl
        );
    }

    return {
        deleteFilesModal: isModalVisible && (
            <ConfirmationModal
                key='delete-file-modal'
                title={messageStrategy.modalTitle}
                confirmLabel={'Delete'}
                onClose={hideModal}
                onConfirm={deleteFiles}
            >
                {modalText}
            </ConfirmationModal>
        ),
        deleteFiles: {
            id: 'delete-files',
            children: 'Delete',
            onClick: showModal,
            leftIcon: CmdDelete16,
            color: 'gray',
            variant: 'text',
            visible: isDeleteFilesCommandAccessible,
        },
    };
}
