import { useTheme } from '@emotion/react';
import { useState } from 'react';

import HorizontalMenuDivider from 'components/atoms/HorizontalMenuDivider';
import AuroraDataPrivacy from 'components/organisms/DataPrivacy';
import { DataPrivacyProps } from 'components/organisms/DataPrivacy/types';
import appConfig from 'constants/appConfig';
import useFeatureFlagsQuery from 'services/queries/featuresFlags.query';
import { Path } from 'types/paths.enum';

import {
    ButtonArea,
    ButtonPanel,
    DataPrivacyIcon,
    Footer,
    FooterElement,
    GuideIcon,
    TermsOfUseIcon,
} from './HelpPanel.styles';

export interface Props {}

const Privacy = ({ open, setOpen }: DataPrivacyProps) => {
    const { data } = useFeatureFlagsQuery();
    if (data?.Analytics_SiemensAuroraAnalyticsEnabled) {
        return <AuroraDataPrivacy open={open} setOpen={setOpen} />;
    }
    console.error('Feature flag for analytics is disabled');
    return null;
};

const HelpPanel = ({}: Props) => {
    const { data } = useFeatureFlagsQuery();
    const [dataOpen, setDataOpen] = useState(false);
    const theme = useTheme();
    const openUserGuide = () => {
        if (data?.WalkMeIntegration) {
            //@ts-ignore
            window.WalkMePlayerAPI?.toggleMenu();
        } else {
            window.open(appConfig.DOCUMENTATION_URL, '_blank', 'noopener,noreferrer');
        }
    };

    return (
        <>
            <h4>Help</h4>
            <ButtonArea onClick={openUserGuide}>
                <ButtonPanel>
                    <GuideIcon />
                    <span>Help</span>
                </ButtonPanel>
            </ButtonArea>
            <HorizontalMenuDivider width={250} />
            <ButtonArea onClick={() => setDataOpen(true)}>
                <ButtonPanel>
                    <DataPrivacyIcon />
                    <span>Data Privacy</span>
                </ButtonPanel>
            </ButtonArea>
            <ButtonArea
                onClick={() => window.open(appConfig.TERMS_OF_USE, '_blank', 'noopener,noreferrer')}
            >
                <ButtonPanel>
                    <TermsOfUseIcon />
                    <span>Terms of Use</span>
                </ButtonPanel>
            </ButtonArea>
            <ButtonArea
                onClick={() => window.open(Path.THIRD_PARTY, '_blank', 'noopener,noreferrer')}
            >
                <ButtonPanel>
                    <TermsOfUseIcon />
                    <span>Third party Software Info</span>
                </ButtonPanel>
            </ButtonArea>
            <Privacy open={dataOpen} setOpen={setDataOpen} />
            <HorizontalMenuDivider width={250} />
            <Footer>
                <FooterElement fontColor={'#FFFFFF'}>
                    &copy; Copyright {new Date().getFullYear()}
                </FooterElement>
                <FooterElement fontColor={theme.colors.panelButtonText}>
                    Siemens Digital Industries Software
                </FooterElement>
            </Footer>
        </>
    );
};

export default HelpPanel;
