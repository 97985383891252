import React from 'react';

import SmallEmptyState from 'components/atoms/SmallEmptyState';
import { ReactComponent as ErrorStateFile } from 'images/NotificationError.svg';

import useNotification from '../../services/queries/notification.query';

const ErrorState: React.FC = () => {
    const { isError, data } = useNotification();
    if (isError && !data) {
        return (
            <SmallEmptyState
                message='Error loading notifications'
                subMessage='Try again later...'
                icon={ErrorStateFile}
                id='ccd-notification-error-state'
            />
        );
    }
    return null;
};

export default ErrorState;
