import { Fragment, useState } from 'react';

import DownloadSuspiciousFileDialog from 'components/organisms/DownloadSuspiciousFileDialog/DownloadSuspiciousFileDialog';
import { ReactComponent as CmdDownloadFile16 } from 'images/cmdDownloadFile16.svg';
import { isScanStatusClean } from 'shared/utility';

import { useCommandBarVisibilityContext, useDownload } from '../../../../hooks';
import { useFileManagementContext } from '../../../FileManagementContext';

export default function useDownloadFile() {
    const { isDownloadCommandAccessible } = useCommandBarVisibilityContext();
    const { anchorRef, downloadFile } = useDownload();
    const {
        state: { selectedFiles },
    } = useFileManagementContext();
    const [showDialog, setShowDialog] = useState(false);

    const DialogComponent = () => (
        <>
            {showDialog ? (
                <DownloadSuspiciousFileDialog
                    onClose={() => setShowDialog(false)}
                    onConfirm={() => {
                        downloadFile();
                        setShowDialog(false);
                    }}
                />
            ) : null}
        </>
    );

    const isFileClean = () => {
        return selectedFiles?.some((file) => {
            return !!file.contentScanInfo ? isScanStatusClean(file.contentScanInfo) : true;
        });
    };

    const downloadSingleNotClean = () => {
        return selectedFiles?.length === 1 && !isFileClean();
    };

    return {
        downloadFileAnchor: downloadSingleNotClean() ? (
            <Fragment key='downloadFileFragment'>
                <a hidden key='downloadFile' id='downloadFile-input' ref={anchorRef} download />
                <DialogComponent key='downloadSuspiciousFileDialog' />
            </Fragment>
        ) : (
            <a hidden key='downloadFile' id='downloadFile-input' ref={anchorRef} download />
        ),
        downloadFile: {
            id: 'downloadFile',
            children: 'Download',
            onClick: downloadSingleNotClean() ? () => setShowDialog(true) : downloadFile,
            leftIcon: CmdDownloadFile16,
            variant: 'text',
            color: 'gray',
            visible: isDownloadCommandAccessible,
        },
    };
}
