import uniqueId from 'lodash/uniqueId';
import { arrayOf, bool, object, string } from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Box from 'components/atoms/Box/Box';

import eventEmitter, { CLOSE_POPUP_MENU } from '../eventEmitter';
import CcdPopupMenuList from './components/CcdPopupMenuList';

import './ccdPopupMenu.scss';

const CcdPopupMenu = ({
    menuButton,
    menuItems,
    direction,
    id,
    menuText,
    useTableOffset,
    closeOthers,
    enableSelection,
}) => {
    const [isActive, setIsActive] = useState(false);

    const hidePopup = () => {
        setIsActive(false);
    };

    useEffect(() => {
        const closeModal = (popupId) => {
            if (closeOthers && popupId !== id) {
                hidePopup();
            }
        };

        const listener = eventEmitter.on(CLOSE_POPUP_MENU, closeModal);

        return () => eventEmitter.removeListener(listener);
    }, [hidePopup, eventEmitter, closeOthers]);

    const onClick = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();

            setIsActive((prevIsActive) => !prevIsActive);

            if (closeOthers) {
                eventEmitter.emit(CLOSE_POPUP_MENU, id);
            }
        },
        [setIsActive]
    );

    const idProp = useMemo(() => {
        const props = {};
        if (id !== undefined) {
            props.id = id.replace(/\s/g, '');
        }
        return props;
    }, []);

    const idPopForMenu = useCallback((name) => {
        const props = {};
        if (id !== undefined) {
            props.id = (id + name).replace(/\s/g, '');
        }
        return props;
    }, []);

    return (
        <div className='menu-container'>
            <button className='menu-button' onClick={onClick} {...idProp} data-testid={id}>
                {menuButton && (
                    <Box
                        as={'img'}
                        src={menuButton}
                        className='menu-img'
                        data-testid={`menu-button-img`}
                        css={(props) => ({
                            maskImage: `url(${menuButton})`,
                            filter: props.colors.filterGlobalIcon,
                        })}
                    />
                )}
                {menuText && <span className='menu-button-text'>{menuText}</span>}
            </button>

            <CcdPopupMenuList
                onClose={hidePopup}
                menuItems={menuItems}
                idPopForMenu={idPopForMenu}
                isActive={isActive}
                direction={direction}
                useTableOffset={useTableOffset}
                enableSelection={enableSelection}
            />
        </div>
    );
};

CcdPopupMenu.propTypes = {
    menuButton: string,
    menuItems: arrayOf(object),
    direction: string,
    id: string,
    menuText: string,
    useTableOffset: bool,
    closeOthers: bool,
    enableSelection: bool,
};

CcdPopupMenu.defaultProps = {
    direction: 'center',
    id: uniqueId('popup-menu-'),
    closeOthers: false,
    enableSelection: false,
};

export default CcdPopupMenu;
