import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import Select, { DropdownIndicatorProps, StylesConfig } from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';

import { ReactComponent as DropDownArrow } from 'images/dropdownArrowDown.svg';

import Box from '../Box';

export interface ISelectList {
    label: string;
    value?: string;
    [k: string]: any;
}

export interface IComboBox extends StateManagerProps {
    selectList?: ISelectList[];
    isError?: boolean;
    components?: { [key: string]: any };
    placement?: 'top' | 'bottom';
    invertedColors?: boolean;
    overrideStyles?: {
        option?: { [k: string]: any };
        control?: { [k: string]: any };
        menu?: { [k: string]: any };
    };
}

const ComboBox = (props: IComboBox) => {
    const { selectList, components, isDisabled, overrideStyles, isError, placement, ...restProps } =
        props;
    const theme = useTheme();

    const defaultStyles: StylesConfig = {
        control: () => ({
            border: `solid 1px ${theme.colors.textArea.border}`,
            borderRadius: '2px',
            display: 'flex',
            padding: '4px',
            justifyContent: 'space-between',
            ':hover': {
                boxShadow: '0 3px 6px 0 rgb(0 0 0 / 30%)',
            },
            ':focus-within': {
                border: `solid 2px ${theme.colors.combobox.border.focus}`,
                padding: '3px',
            },
            ...(isDisabled && {
                backgroundColor: 'hsl(0, 0%, 95%)',
                borderColor: 'hsl(0, 0%, 90%)',
                boxShadow: '0',
                pointerEvents: 'none',
            }),
            ...(isError && {
                borderColor: theme.colors.combobox.border.error,
                backgroundColor: theme.colors.combobox.border.errorBackground,
                ':focus-within': { borderWidth: '2px', padding: '3px' },
            }),
            ...overrideStyles?.control,
        }),
        placeholder: () => ({ fontStyle: 'italic', opacity: '0.5' }),
        valueContainer: () => ({
            display: 'flex',
            flexDirection: 'row-reverse',
            fontStyle: 'italic',
        }),
        menu: () => ({
            borderRadius: '3px',
            boxShadow: `0px 3px 6px ${theme.colors.shadow}`,
            position: 'absolute',
            zIndex: '20',
            backgroundColor: theme.colors.background,
            width: '100%',
            ...(placement === 'top' && { bottom: '34px' }),
            ...overrideStyles?.menu,
        }),
        option: (_, state) => ({
            color: theme.colors.text,
            cursor: 'pointer',
            padding: '4px 4px',
            borderRadius: '3px',
            ':hover': {
                backgroundColor: theme.colors.table.row.hover,
            },
            ...(state.isSelected && {
                backgroundColor: theme.colors.table.row.select,
            }),
            ...overrideStyles?.option,
        }),
    };

    const DropDownIcon = styled(DropDownArrow)(({ theme }) => ({
        height: '8px',
        width: '8px',
        fill: theme.colors.text,
        ...(isDisabled && { opacity: '0.4' }),
    }));

    const DropdownIndicator = (props: DropdownIndicatorProps) => {
        const {
            innerProps: { ref, ...restInnerProps },
            selectProps: { menuIsOpen },
        } = props;
        return (
            <Box
                {...restInnerProps}
                ref={ref}
                css={{ ...(menuIsOpen && { transform: 'rotate(180deg)' }) }}
            >
                <DropDownIcon />
            </Box>
        );
    };

    return (
        <Select
            options={selectList}
            styles={defaultStyles}
            unstyled
            components={{ DropdownIndicator, ...components }}
            isDisabled={isDisabled}
            {...restProps}
        />
    );
};

export default ComboBox;
