import { MouseEventHandler, useId, useMemo } from 'react';

import { CcdEmptyState } from 'components/_legacy/ccd-empty-state';
import { CcdRelativeDateCellRenderer } from 'components/_legacy/ccd-relative-date-cell-renderer';
import { CcdTableColumn } from 'components/_legacy/ccd-table';
import Box from 'components/atoms/Box';
import ProgressSpinner from 'components/atoms/ProgressSpiner';
import useMoveSorting from 'components/templates/FileManagement/components/parts/MoveFileForm/useMoveSorting';
import { ISelectedFile } from 'components/templates/FileManagement/contexts/FileManagementContext';
import { CurrentFolder } from 'components/templates/FileManagement/types';
import { ReactComponent as EmptyStateFile } from 'images/Empty-State-File.svg';
import { ReactComponent as ErrorStateFile } from 'images/Error-State-icon.svg';

import { TableComponent, useListItem } from '../../parts/Table';
import FileNameCellRenderer from '../Table/FileNameCellRenderer';
import usePagination from '../Table/usePagination';

export default function MoveTable({
    currentFolder,
    selectedFiles,
    mainTableSelection,
    dispatchFileManagement,
    className,
}: {
    currentFolder: CurrentFolder;
    selectedFiles: ISelectedFile[];
    mainTableSelection: ISelectedFile[];
    dispatchFileManagement: () => void;
    className?: string;
}) {
    const id = useId();
    const { paginationMemo } = usePagination();
    const { settings, moveSortingHandler, data, isError, isLoading } =
        useMoveSorting(currentFolder);

    const { selectionHandler, onFileClickHandler } = useListItem(
        currentFolder,
        dispatchFileManagement
    );

    const tableSettings = useMemo(
        () => ({
            suppressOutsideClick: true,
            initialSortSettings: {
                sortedColumnName: settings.sortColumn,
                sortDirection: settings.sortOrder,
            },
        }),
        [settings]
    );

    const columns: CcdTableColumn<ISelectedFile>[] = [
        {
            Header: 'Name',
            accessor: 'name',
            width: 150,
            ccdSorted: true,
            Cell: (cell) => {
                return (
                    <FileNameCellRenderer
                        onCellNameClick={onFileClickHandler}
                        cell={cell}
                        isItemOpenable={(item: ISelectedFile) => item.isFolder}
                    />
                );
            },
        },
        {
            Header: 'Last Modified Date',
            accessor: 'lastModifiedDate',
            ccdSorted: true,
            width: 150,
            Cell: CcdRelativeDateCellRenderer,
        },
    ];

    columns.forEach((item) => {
        item.tableId = id;
    });

    const rowRenderer = (args: any) => {
        const onClick = (event: MouseEventHandler<HTMLDivElement>) => {
            if (args.row.original.isFolder) {
                args.rowArgs.onClick(event);
            }
        };
        return (
            <Box
                {...args.rowArgs}
                css={{
                    ...args.rowArgs.css,
                    ...(!args.row.original.isFolder && {
                        opacity: 0.5,
                        '&:hover': {
                            backgroundColor: 'transparent',
                        },
                    }),
                }}
                className={`${args.rowArgs.className}`}
                onClick={onClick}
            >
                {args.children}
            </Box>
        );
    };

    return (
        <>
            {!data && isLoading && <ProgressSpinner />}
            <div id='moveTable' className={`${className}`}>
                {!!data?.length && (
                    <TableComponent
                        data={data.filter(
                            (file) =>
                                !mainTableSelection.find(
                                    (selectedFile) => selectedFile.id === file.id
                                )
                        )}
                        columns={columns}
                        rowHandler={selectionHandler}
                        doubleClickHandler={onFileClickHandler}
                        sortingHandler={moveSortingHandler}
                        tableSettings={tableSettings}
                        paginationMemo={paginationMemo}
                        rowRenderer={rowRenderer}
                        selectedFiles={selectedFiles}
                    />
                )}
                {!data && isError && (
                    <CcdEmptyState
                        message='Failed to load files'
                        subMessage='Try to refresh the page'
                        icon={ErrorStateFile}
                    />
                )}
                {!data?.length && !isError && !isLoading && (
                    <CcdEmptyState message='No files to display' icon={EmptyStateFile} />
                )}
            </div>
        </>
    );
}
